.not-found {
    @include flexCenter(column);
    height: 100vh;
    background: $bg;
    .upper-content {
        @include flexCenter(column);
        margin-bottom: 45px;
        .stay-calm {
            color: $accent;
            font-weight: 400;
            font-size: 2.5rem;
        }
        .face {
            margin-top: 20px;
            height: 90px;
        }
        .oops {
            margin: 20px 0;
            max-width: 200px;
            text-align: center;
            color: $text;
        }
    }
    .home {
        cursor: pointer;
        background: $bg;
        color: $textLight;
        border: 2px white solid;
        border-radius: 30px;
        height: 40px;
        width: 120px;
        &:focus {
            outline: none;
        }
    }
    @media (max-height: 415px) {
        .oops {
            margin-bottom: 0;
        }
        .home {
            margin-bottom: 20px;
        }
    }
}