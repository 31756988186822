.admin-blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: whitesmoke;
    border-radius: 27px;
    padding: 20px;
    min-width: 624.48px;
    margin: 30px;
    span {
        font-weight: bold;
    }
    input {
        margin: 10px;
        min-width: 400px;
    }
    .upload {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}