.dashboard-container-outer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.dashboard-container {
    @media (min-width: 800px) {
        grid-template: 1fr 1fr 1fr / 1fr 1fr;
    }
    @media (min-width: $desktopBreak) {
        padding-left: 200px;
        padding-bottom: 0;
    }
    @media (max-height: 440px) and (max-width: $desktopBreak) {
        padding-left: 100px;
        padding-bottom: 0;
    }
    width: 100%;
    display: grid;
    justify-items: center;
    grid-template: 1fr / 1fr;
    padding-bottom: 90px;
    max-width: 1230px;
    .settings {
        @media (min-width: 800px) {
            justify-content: center;
            width: 90%;
        }
        @include flexCenter(row);
        position: relative;
        justify-content: space-between;
        width: 65%;
        padding: 20px 0;
        z-index: 1;
        @media (min-width: 800px) {
            transform: scale(1.2);
            height: 70%;
        }
        .text {
            @media (min-width: 800px) {
                top: 435px;
                left: 20%;
            }
            @media (min-width: $desktopBreak) {
                top: 435px;
                left: 19%;
            }
            position: absolute;
            top: 555px;
            left: 6px;
            color: $accent;
            font-size: 1.3rem;
        }
        .text-favorites {
            @media (min-width: 800px) {
                top: 168px;
                left: 20%;
            }
            @media (min-width: $desktopBreak) {
                top: 168px;
                left: 19%;
            }
            color: $accent;
            font-size: 1.3rem;
            margin-bottom: 15px;
            position: absolute;
            top: 235px;
            left: 6px;
        }
        .settings-left {
            margin-right: 20px;
            .settings-welcome {
                color: white;
                font-weight: 600;
                font-size: 1.2rem;
                margin: 0;
                text-align: center;
                max-width: 190px;
            }
        }
        .settings-right {
            cursor: pointer;
            margin-left: 10px;
            @media (min-width: 800px) {
                margin-right: -50px;
            }
            @include flexCenter(column);
            .settings-image {
                height: 40px;
            }
            div {
                color: #848484;
                font-size: 0.8rem;
            }
        }
    }
    .up-next {
        @media (min-width: 800px) {
            height: 70%;
            flex-direction: column;
            justify-content: center;
        }
        opacity: 1;
        background: $bg;
        height: 100px;
        width: 90%;
        max-width: 463.05px;
        border-radius: 20px;
        padding: 20px;
        margin: 0 20px 20px 20px;
        @include flexCenter(row);
        justify-content: space-around;
        .up-next-left {
            @media (min-width: 800px) {
                margin-left: 0;
            }
            @include flexCenter(column);
            align-items: flex-start;
            .up-next-text {
                color: white;
                font-weight: 600;
                letter-spacing: 1px;
            }
            .next-track {
                color: $accent;
            }
        }
        .up-next-right {
            button {
                @media (min-width: 800px) {
                    width: 180px;
                    margin-top: 10px;
                    margin-left: 20px;
                }
                background: linear-gradient(50deg, #845aff 15%, #d66365 100%);
                &:hover {
                    filter: brightness(120%);
                }
                &:focus {
                    outline: none;
                }
                transition: 500ms;
                cursor: pointer;
                border: 9px solid $bgLight;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                width: 130px;
                height: 60px;
                border-radius: 28px;
                color: white;
                font-weight: bold;
                font-size: 1rem;
                letter-spacing: 1px;
            }
        }
    }
    .hidden {
        opacity: 0;
    }
    .frequent {
        @media (min-width: 600px) {
            max-width: 543.05px;
            min-width: 400px;
            width: 100%;
        }
        @media (min-width: 800px) {
            grid-area: 3 / 1 / 4 / 3;
            width: 92.5%;
            max-width: 951.81px;
            margin-top: -50px;
        }
        @media (min-width: 1050px) {
            min-width: 750.83px;
        }
        @media (min-width: 1200px) {
            min-width: 885.83px;
        }
        @include flexCenter(row);
        justify-content: flex-start;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
        overflow-x: scroll;
        overflow-y: hidden;
        position: relative;
        background: $bgLight;
        height: 300px;
        width: 90%;
        min-width: 288px;
        max-width: 463.05px;
        border-radius: 20px;
        padding: 20px;
        margin: 0 20px 20px 20px;
        .frequent-text {
            @media (min-width: 800px) {
                margin-left: 10%;
                margin-top: 30px;
            }
            @media (min-width: $desktopBreak) {
                margin-left: calc(50% - 230px);
            }
            .repeat {
                color: #c7c7c7;
            }
            p {
                margin: 0;
                color: #848484;
                max-width: 500px;
            }
        }
    }
    .favorites {
        @media (min-width: 600px) {
            max-width: 543.05px;
            min-width: 400px;
            width: 100%;
        }
        @media (min-width: 800px) {
            grid-area: 2 / 1 / 4 / 3;
            width: 92.5%;
            max-width: 951.81px;
            margin-top: -100px;
        }
        @media (min-width: 1050px) {
            min-width: 750.83px;
        }
        @media (min-width: 1200px) {
            min-width: 885.83px;
        }
        @include flexCenter(row);
        justify-content: flex-start;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
        overflow-x: scroll;
        overflow-y: hidden;
        position: relative;
        background: $bgLight;
        height: 300px;
        width: 90%;
        min-width: 288px;
        max-width: 463.05px;
        border-radius: 20px;
        padding: 20px;
        margin: 0 20px 20px 20px;
    }
    .favorites-default {
        height: 200px;
        margin-top: 20px;
    }
}

.dark-bg-settings {
    position: fixed;
    @include flexCenter(column);
    backdrop-filter: blur(2px);
    transition: 300ms;
    z-index: -1;
    opacity: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
}

.add-name-modal {
    @include flexCenter(column);
    @media (min-width: $desktopBreak) {
        margin-left: 200px;
    }
    min-width: 270px;
    width: 85%;
    max-width: 400px;
    height: 50%;
    min-height: 365px;
    max-height: 400px;
    border-radius: 30px;
    border: solid 1px #707070;
    background-color: #ffffff;
    justify-content: space-evenly;
    padding: 30px 20px;
    .welcome {
        text-align: center;
        font-weight: 700;
        font-size: 1.3rem;
        color: #7a70ff;
    }
    .first-name {
        display: flex;
        flex-direction: column;
        text-align: left;
        color: #7a70ff;
        font-size: 0.9rem;
        span {
            margin: 0 0 8px 10px;
        }
        input {
            height: 30px;
            width: 224px;
            border-radius: 25px;
            border: solid 1px #707070;
            padding: 0 15px;
            &:focus {
                outline: none;
            }
        }
    }
    .last-name {
        display: flex;
        flex-direction: column;
        text-align: left;
        color: #7a70ff;
        font-size: 0.9rem;
        span {
            margin: 0 0 8px 10px;
        }
        input {
            height: 30px;
            width: 224px;
            border-radius: 25px;
            border: solid 1px #707070;
            padding: 0 15px;
            &:focus {
                outline: none;
            }
        }
    }
    .buttons {
        margin-top: 10px;
        .later {
            border-radius: 25px;
            background: white;
            color: #7a70ff;
            border: solid 1px #7a70ff;
            margin-right: 5px;
            padding: 10px 18px;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
        .submit {
            background: #7a70ff;
            color: white;
            border-radius: 25px;
            border: none;
            margin-left: 5px;
            padding: 10px;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
    }
}

.favorites::-webkit-scrollbar {
    @media (min-width: 800px) {
        display: unset;
    }
    display: none;
    /* Safari and Chrome */
}

.frequent::-webkit-scrollbar {
    @media (min-width: 800px) {
        display: unset;
    }
    display: none;
    /* Safari and Chrome */
}

.payment-failed-outer {
    .dismiss {
        height: 20px;
        filter: (brightness(0) invert(1));
    }
    @media (min-width: 630px) {
        width: 60%;
        opacity: 0.7;
    }
    @media (min-width: $desktopBreak) {
        width: 40%;
        margin-left: 200px;
    }
    @include flexCenter(row);
    padding: 0px 10px;
    justify-content: space-around;
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    background: rgb(145, 69, 69);
    color: white;
    text-align: center;
    z-index: 5;
    opacity: 0.8;
    cursor: pointer;
    transition: 500ms;
    &:hover {
        opacity: 1;
    }
}