.tracks-list-container {
    @media (min-width: $desktopBreak) {
        padding: 85px 90px 0 290px;
        height: 100vh;
        display: grid;
        justify-items: center;
        grid-template: 1fr / 1fr 1fr;
    }
    @media (min-width: 1240px) {
        grid-template: 1fr / 1fr 1fr 1fr;
    }
    @media (min-width: 1480px) {
        grid-template: 1fr / 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1570px) {
        padding: 85px 6% 0 calc(6% + 200px);
    }
    @media (max-height: 440px) {
        padding: 0 0 0 100px;
        height: 100vh;
    }
    @include flexCenter(row);
    justify-content: flex-start;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    overflow-x: scroll;
    height: 85vh;
    padding: 20px 20px 15px 20px;
    nav {
        @media (min-width: $desktopBreak) {
            left: 250px;
        }
        @media (max-height: 440px) {
            left: 150px;
        }
        &:hover {
            filter: brightness(150%);
        }
        @include flexCenter(row);
        z-index: 1;
        cursor: pointer;
        background: $bg;
        height: 30px;
        padding: 0 10px;
        border-radius: 30px;
        transition: 500ms;
        position: fixed;
        left: 10px;
        top: 15px;
        color: $accent;
        font-weight: 500;
        .back-arrow {
            height: 20px;
            margin-right: 10px;
        }
    }
    .loading-results {
        @media (min-width: $desktopBreak) {
            width: 80vw;
        }
        height: 70vh;
        width: 100%;
        @include flexCenter(column);
    }
}

.tracks-list-container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}