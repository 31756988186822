.subscribe-modal-container {
    @media (min-width: 450px) {
        padding: 30px 40px;
        max-width: 80%;
    }
    @media (min-width: 800px) {
        max-width: 40%;
        max-height: 85%;
    }
    @media (max-width: $desktopBreak) {
        padding: 20px 5% 30px 5%;
        max-height: 80vh;
        margin-top: -70px;
    }
    @media (min-width: $desktopBreak) and (max-height: 440px) {
        margin: 0 0 0 100px;
    }
    @media (min-width: $desktopBreak) and (min-height: 440px) {
        margin: 0 0 0 200px;
    }
    @include flexCenter(column);
    max-width: 90%;
    border-radius: 30px;
    border: solid 1px #707070;
    background-color: #ffffff;
    justify-content: flex-start;
    padding: 30px 15px;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    overflow-x: scroll;
    .title-modal {
        color: $accent;
        font-size: 1.1rem;
        font-weight: 600;
        text-align: center;
    }
    .logo-modal {
        margin-bottom: 30px;
    }
    .modal-buttons-container {
        @include flexCenter(row);
        margin-top: 15px;
        .confirm {
            background: $accent;
            color: white;
            border: none;
            border-radius: 27px;
            padding: 10px 15px;
            margin-right: 5px;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
        .later {
            margin-left: 5px;
            color: #707070;
            background: white;
            border: #707070 1px solid;
            border-radius: 27px;
            padding: 10px 15px;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
    }
    .logo-modal {
        @include flexCenter(column);
        .logo-image-modal {
            height: 75px;
        }
        .logo-text-modal {
            height: 25px;
            filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%);
        }
    }
    a {
        color: $accent;
        text-decoration: none;
        display: inline;
        cursor: pointer;
    }
    main {
        ul {
            @media (min-width: 450px) {
                margin-left: 0;
            }
            font-size: .9rem;
            margin-left: -20px;
            font-weight: 600;
        }
    }
}

.subscribe-modal-container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}