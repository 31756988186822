.player-container {
    @media (min-width: $desktopBreak) {
        padding-left: 200px;
        padding-bottom: 0;
    }
    @media (max-height: 440px) {
        padding-left: 100px;
        padding-bottom: 0;
    }
    width: auto;
    padding-top: 60px;
    padding-bottom: 100px;
    @include flexCenter(column);
    justify-content: flex-start;
    nav {
        @media (min-width: $desktopBreak) {
            left: 250px;
        }
        @media (max-height: 440px) {
            left: 150px;
        }
        transition: 500ms;
        &:hover {
            filter: brightness(150%);
        }
        font-family: 'Poppins',
        sans-serif;
        z-index: 2;
        cursor: pointer;
        @include flexCenter(row);
        position: fixed;
        background: $bg;
        height: 30px;
        padding: 0 10px;
        border-radius: 30px;
        left: 10px;
        top: 15px;
        color: $accent;
        font-weight: 500;
        .back-arrow {
            height: 20px;
            margin-right: 10px;
        }
    }
    .circle {
        height: 100px;
        width: 100px;
        box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.192);
        border-radius: 50%;
    }
    .player {
        position: relative;
        @media (min-width: 760px) {
            transition: 700ms;
            min-width: 750px;
            align-items: flex-start;
            padding: 0 75px;
            background-repeat: no-repeat;
            background-size: cover;
            background: linear-gradient(rgba(15, 26, 64, 0.7), rgba(15, 26, 64, 0.7)), var(--background-url);
        }
        @media (max-height: 440px) and (min-width: 760px) {
            min-height: 300px;
            min-width: 650px;
            padding: 0 40px;
            transition: 700ms;
        }
        @include flexCenter(column);
        justify-content: space-evenly;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.192);
        padding: 20px;
        min-height: 330px;
        width: 300px;
        border-radius: 32.7px;
        background: $bgLight;
        .title {
            @media (min-width: 760px) {
                text-align: left;
                width: 50%;
            }
            font-family: 'Poppins-light';
            margin: 0 0 7px 0;
            font-size: 2rem;
            font-weight: 450;
            text-align: center;
            width: 90%;
            line-height: 30px;
        }
        .length {
            @media (min-width: 760px) {
                margin: -30px 0 30px 0;
            }
            margin: -10px 0 10px 0;
            color: $accent;
            font-size: 0.8rem;
            font-weight: 500;
        }
        .description {
            @media (min-width: 760px) {
                display: unset;
                flex-wrap: wrap;
                width: 40%;
                margin: -40px 0 0 0;
                line-height: 20px;
            }
            display: none;
            color: rgba(255, 255, 255, 0.658);
            font-weight: 400;
            font-size: 0.9rem;
        }
        .controls-container {
            @include flexCenter(column);
            @media (min-width: 760px) {
                position: absolute;
                right: 75px;
            }
            @media (max-height: 440px) {
                right: 40px;
            }
            .restart-container {
                @include flexCenter(row);
                padding: 3px 12px;
                border: 2px solid white;
                border-radius: 27px;
                color: white;
                cursor: pointer;
                letter-spacing: 1px;
                font-size: .9rem;
                margin-top: 5px;
                .restart {
                    margin-right: 10px;
                }
            }
        }
        .controls {
            position: relative;
            @include flexCenter(row);
            .back-container {
                @include flexCenter(column);
                margin-top: 25px;
                color: rgba(255, 255, 255, 0.808);
                font-weight: 300;
                font-size: 0.8rem;
                cursor: pointer;
                transition: 500ms;
                .back {
                    &:hover {
                        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.178);
                    }
                    border-radius: 50%;
                    transition: 500ms;
                    margin-bottom: 10px;
                    height: 30px;
                }
            }
            .forward-container {
                @include flexCenter(column);
                margin-top: 25px;
                color: rgba(255, 255, 255, 0.808);
                font-weight: 300;
                font-size: 0.8rem;
                cursor: pointer;
                transition: 500ms;
                .forward {
                    &:hover {
                        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.178);
                    }
                    border-radius: 50%;
                    transition: 500ms;
                    margin-bottom: 10px;
                    height: 30px;
                }
            }
            .circle {
                margin: 0 25px;
            }
            .play {
                cursor: pointer;
                &:hover {
                    filter: brightness(120%);
                }
                transition: 500ms;
                position: absolute;
                height: 66px;
                left: calc(50% - 35px);
                top: 17px;
                border-radius: 100%;
            }
        }
        .current-time {
            color: rgba(255, 255, 255, 0.808);
            font-weight: 300;
            margin-top: 5px;
            margin-right: 5px;
        }
        .audio-toggle {
            @include flexCenter(row);
            margin-top: 20px;
            .all-audio-container {
                @include flexCenter(row);
                span {
                    margin-right: -5px;
                }
                cursor: pointer;
                transition: 300ms;
                margin-right: -10px;
                padding: 6px 30px 6px 15px;
                border-radius: 15px;
                background: $bg;
                color: #cc83c8;
                font-weight: 500;
                font-size: 0.8rem;
                .all-audio {
                    height: 20px;
                    margin-right: 7px;
                }
            }
            .all-audio-container-selected {
                @include flexCenter(row);
                span {
                    margin-right: -5px;
                }
                cursor: pointer;
                z-index: 1;
                transition: 300ms;
                margin-right: -10px;
                padding: 6px 30px 6px 15px;
                border-radius: 15px;
                background: white;
                color: #cc83c8;
                font-weight: 500;
                font-size: 0.8rem;
                .all-audio {
                    height: 20px;
                    margin-right: 7px;
                }
            }
            .only-music-container {
                @include flexCenter(row);
                cursor: pointer;
                transition: 300ms;
                margin-left: -10px;
                padding: 6px 20px 6px 25px;
                border-radius: 15px;
                background: $bg;
                color: #cc83c8;
                font-weight: 500;
                font-size: 0.8rem;
            }
            .only-music-container-selected {
                @include flexCenter(row);
                cursor: pointer;
                z-index: 1;
                transition: 300ms;
                margin-left: -10px;
                padding: 6px 20px 6px 25px;
                border-radius: 15px;
                background: white;
                color: #cc83c8;
                font-weight: 500;
                font-size: 0.8rem;
            }
            .only-music {
                height: 20px;
                margin-right: 7px;
            }
        }
    }
}

.player-listened-show {
    @media (min-width: 760px) {
        min-width: 750px;
        padding: 0 75px;
    }
    @media (max-height: 440px) and (min-width: 760px) {
        min-width: 650px;
        padding: 0 40px;
    }
    @include flexCenter(column);
    position: absolute;
    opacity: 100;
    transition: 700ms;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(rgba(15, 26, 64, 0.7), rgba(15, 26, 64, 0.7)),
    var(--background-url);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    min-height: 330px;
    width: 300px;
    border-radius: 32.7px;
    .check {
        position: absolute;
        height: 100px;
        top: -15px;
        left: calc(50% - 50px);
    }
    .you-are {
        color: white;
        font-weight: 500;
        font-size: 1.1rem;
    }
    .level {
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .relisten {
        cursor: pointer;
        border: 1px solid white;
        border-radius: 30px;
        color: white;
        font-size: 0.8rem;
        letter-spacing: 1px;
        padding: 5px 15px;
        @include flexCenter(row);
        .relisten-arrow {
            @media (min-width: 760px) {
                display: unset;
            }
            @media (max-height: 440px) and (min-width: 760px) {
                display: unset;
            }
            height: 15px;
            margin-right: 10px;
            display: none;
        }
    }
    .next {
        position: absolute;
        width: 300px;
        height: 105px;
        left: calc(50% - 150px);
        bottom: -15px;
        background: white;
        color: #7a70ff;
        border-radius: 60px;
        cursor: pointer;
        @include flexCenter(row);
        justify-content: space-around;
        padding: 0 5px;
        .text {
            margin-left: -10px;
        }
        .next-track-image {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            object-fit: cover;
            opacity: 100 !important;
        }
        p {
            margin: 0;
            font-size: 1rem;
        }
        .track-name {
            font-size: 1.2rem;
            font-weight: 700;
        }
        .next-arrow {
            height: 20px;
            margin-bottom: -20px;
        }
    }
}

.player-listened-hide {
    opacity: 0;
    transition: 700ms;
    @media (min-width: 760px) {
        min-width: 750px;
        padding: 0 75px;
    }
    @media (max-height: 440px) and (min-width: 760px) {
        min-width: 650px;
        padding: 0 40px;
    }
    @include flexCenter(column);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(rgba(15, 26, 64, 0.7), rgba(15, 26, 64, 0.7)),
    var(--background-url);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    min-height: 330px;
    width: 300px;
    border-radius: 32.7px;
    .check {
        position: absolute;
        height: 100px;
        top: -15px;
        left: calc(50% - 50px);
    }
    .you-are {
        color: white;
        font-weight: 500;
        font-size: 1.1rem;
    }
    .level {
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .relisten {
        cursor: pointer;
        border: 1px solid white;
        border-radius: 30px;
        color: white;
        font-size: 0.8rem;
        letter-spacing: 1px;
        padding: 5px 15px;
        @include flexCenter(row);
        .relisten-arrow {
            @media (min-width: 760px) {
                display: unset;
            }
            @media (max-height: 440px) and (min-width: 760px) {
                display: unset;
            }
            height: 15px;
            margin-right: 10px;
            display: none;
        }
    }
    .next {
        position: absolute;
        width: 300px;
        height: 105px;
        left: calc(50% - 150px);
        bottom: -15px;
        background: white;
        color: #7a70ff;
        border-radius: 60px;
        cursor: pointer;
        @include flexCenter(row);
        justify-content: space-around;
        padding: 0 5px;
        .text {
            margin-left: -10px;
        }
        .next-track-image {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            object-fit: cover;
        }
        p {
            margin: 0;
            font-size: 1rem;
        }
        .track-name {
            font-size: 1.2rem;
            font-weight: 700;
        }
        .next-arrow {
            height: 20px;
            margin-bottom: -20px;
        }
    }
}