.learn-container {
    @media (min-width: $desktopBreak) {
        padding-left: 200px;
        padding-bottom: 0;
    }
    @media (max-height: 440px) {
        padding-left: 100px;
        padding-bottom: 0;
    }
    padding-bottom: 90px;
    @include flexCenter(column);
    h1 {
        color: $accent;
    }
    .posts-container {
        @media (min-width: 760px) {
            display: grid;
            grid-template: 1fr / 1fr 1fr;
        }
        @media (min-width: $desktopBreak) {
            @include flexCenter(column);
            width: 100vw;
        }
    }
}