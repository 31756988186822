@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@font-face {
    font-family: 'Poppins-Light';
    src: url(/static/media/Poppins-Light.ttf.e4f6595f.woff) format('woff'), url(/static/media/Poppins-Light.ttf.951f33da.svg) format('svg'), url(/static/media/Poppins-Light.ttf.f0e83400.eot);
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.dashboard-container-outer {
  width: 100%;
  display: flex;
  justify-content: center; }

.dashboard-container {
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template: 1fr / 1fr;
  padding-bottom: 90px;
  max-width: 1230px; }
  @media (min-width: 800px) {
    .dashboard-container {
      grid-template: 1fr 1fr 1fr / 1fr 1fr; } }
  @media (min-width: 1000px) {
    .dashboard-container {
      padding-left: 200px;
      padding-bottom: 0; } }
  @media (max-height: 440px) and (max-width: 1000px) {
    .dashboard-container {
      padding-left: 100px;
      padding-bottom: 0; } }
  .dashboard-container .settings {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    width: 65%;
    padding: 20px 0;
    z-index: 1; }
    @media (min-width: 800px) {
      .dashboard-container .settings {
        justify-content: center;
        width: 90%; } }
    @media (min-width: 800px) {
      .dashboard-container .settings {
        transform: scale(1.2);
        height: 70%; } }
    .dashboard-container .settings .text {
      position: absolute;
      top: 555px;
      left: 6px;
      color: #a89cd9;
      font-size: 1.3rem; }
      @media (min-width: 800px) {
        .dashboard-container .settings .text {
          top: 435px;
          left: 20%; } }
      @media (min-width: 1000px) {
        .dashboard-container .settings .text {
          top: 435px;
          left: 19%; } }
    .dashboard-container .settings .text-favorites {
      color: #a89cd9;
      font-size: 1.3rem;
      margin-bottom: 15px;
      position: absolute;
      top: 235px;
      left: 6px; }
      @media (min-width: 800px) {
        .dashboard-container .settings .text-favorites {
          top: 168px;
          left: 20%; } }
      @media (min-width: 1000px) {
        .dashboard-container .settings .text-favorites {
          top: 168px;
          left: 19%; } }
    .dashboard-container .settings .settings-left {
      margin-right: 20px; }
      .dashboard-container .settings .settings-left .settings-welcome {
        color: white;
        font-weight: 600;
        font-size: 1.2rem;
        margin: 0;
        text-align: center;
        max-width: 190px; }
    .dashboard-container .settings .settings-right {
      cursor: pointer;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      @media (min-width: 800px) {
        .dashboard-container .settings .settings-right {
          margin-right: -50px; } }
      .dashboard-container .settings .settings-right .settings-image {
        height: 40px; }
      .dashboard-container .settings .settings-right div {
        color: #848484;
        font-size: 0.8rem; }
  .dashboard-container .up-next {
    opacity: 1;
    background: #272A3D;
    height: 100px;
    width: 90%;
    max-width: 463.05px;
    border-radius: 20px;
    padding: 20px;
    margin: 0 20px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-around; }
    @media (min-width: 800px) {
      .dashboard-container .up-next {
        height: 70%;
        flex-direction: column;
        justify-content: center; } }
    .dashboard-container .up-next .up-next-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      align-items: flex-start; }
      @media (min-width: 800px) {
        .dashboard-container .up-next .up-next-left {
          margin-left: 0; } }
      .dashboard-container .up-next .up-next-left .up-next-text {
        color: white;
        font-weight: 600;
        letter-spacing: 1px; }
      .dashboard-container .up-next .up-next-left .next-track {
        color: #a89cd9; }
    .dashboard-container .up-next .up-next-right button {
      background: linear-gradient(50deg, #845aff 15%, #d66365 100%);
      transition: 500ms;
      cursor: pointer;
      border: 9px solid #353849;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      width: 130px;
      height: 60px;
      border-radius: 28px;
      color: white;
      font-weight: bold;
      font-size: 1rem;
      letter-spacing: 1px; }
      @media (min-width: 800px) {
        .dashboard-container .up-next .up-next-right button {
          width: 180px;
          margin-top: 10px;
          margin-left: 20px; } }
      .dashboard-container .up-next .up-next-right button:hover {
        -webkit-filter: brightness(120%);
                filter: brightness(120%); }
      .dashboard-container .up-next .up-next-right button:focus {
        outline: none; }
  .dashboard-container .hidden {
    opacity: 0; }
  .dashboard-container .frequent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    background: #353849;
    height: 300px;
    width: 90%;
    min-width: 288px;
    max-width: 463.05px;
    border-radius: 20px;
    padding: 20px;
    margin: 0 20px 20px 20px; }
    @media (min-width: 600px) {
      .dashboard-container .frequent {
        max-width: 543.05px;
        min-width: 400px;
        width: 100%; } }
    @media (min-width: 800px) {
      .dashboard-container .frequent {
        grid-area: 3 / 1 / 4 / 3;
        width: 92.5%;
        max-width: 951.81px;
        margin-top: -50px; } }
    @media (min-width: 1050px) {
      .dashboard-container .frequent {
        min-width: 750.83px; } }
    @media (min-width: 1200px) {
      .dashboard-container .frequent {
        min-width: 885.83px; } }
    @media (min-width: 800px) {
      .dashboard-container .frequent .frequent-text {
        margin-left: 10%;
        margin-top: 30px; } }
    @media (min-width: 1000px) {
      .dashboard-container .frequent .frequent-text {
        margin-left: calc(50% - 230px); } }
    .dashboard-container .frequent .frequent-text .repeat {
      color: #c7c7c7; }
    .dashboard-container .frequent .frequent-text p {
      margin: 0;
      color: #848484;
      max-width: 500px; }
  .dashboard-container .favorites {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    background: #353849;
    height: 300px;
    width: 90%;
    min-width: 288px;
    max-width: 463.05px;
    border-radius: 20px;
    padding: 20px;
    margin: 0 20px 20px 20px; }
    @media (min-width: 600px) {
      .dashboard-container .favorites {
        max-width: 543.05px;
        min-width: 400px;
        width: 100%; } }
    @media (min-width: 800px) {
      .dashboard-container .favorites {
        grid-area: 2 / 1 / 4 / 3;
        width: 92.5%;
        max-width: 951.81px;
        margin-top: -100px; } }
    @media (min-width: 1050px) {
      .dashboard-container .favorites {
        min-width: 750.83px; } }
    @media (min-width: 1200px) {
      .dashboard-container .favorites {
        min-width: 885.83px; } }
  .dashboard-container .favorites-default {
    height: 200px;
    margin-top: 20px; }

.dark-bg-settings {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  transition: 300ms;
  z-index: -1;
  opacity: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.24);
  top: 0;
  left: 0; }

.add-name-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 270px;
  width: 85%;
  max-width: 400px;
  height: 50%;
  min-height: 365px;
  max-height: 400px;
  border-radius: 30px;
  border: solid 1px #707070;
  background-color: #ffffff;
  justify-content: space-evenly;
  padding: 30px 20px; }
  @media (min-width: 1000px) {
    .add-name-modal {
      margin-left: 200px; } }
  .add-name-modal .welcome {
    text-align: center;
    font-weight: 700;
    font-size: 1.3rem;
    color: #7a70ff; }
  .add-name-modal .first-name {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #7a70ff;
    font-size: 0.9rem; }
    .add-name-modal .first-name span {
      margin: 0 0 8px 10px; }
    .add-name-modal .first-name input {
      height: 30px;
      width: 224px;
      border-radius: 25px;
      border: solid 1px #707070;
      padding: 0 15px; }
      .add-name-modal .first-name input:focus {
        outline: none; }
  .add-name-modal .last-name {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #7a70ff;
    font-size: 0.9rem; }
    .add-name-modal .last-name span {
      margin: 0 0 8px 10px; }
    .add-name-modal .last-name input {
      height: 30px;
      width: 224px;
      border-radius: 25px;
      border: solid 1px #707070;
      padding: 0 15px; }
      .add-name-modal .last-name input:focus {
        outline: none; }
  .add-name-modal .buttons {
    margin-top: 10px; }
    .add-name-modal .buttons .later {
      border-radius: 25px;
      background: white;
      color: #7a70ff;
      border: solid 1px #7a70ff;
      margin-right: 5px;
      padding: 10px 18px;
      cursor: pointer; }
      .add-name-modal .buttons .later:focus {
        outline: none; }
    .add-name-modal .buttons .submit {
      background: #7a70ff;
      color: white;
      border-radius: 25px;
      border: none;
      margin-left: 5px;
      padding: 10px;
      cursor: pointer; }
      .add-name-modal .buttons .submit:focus {
        outline: none; }

.favorites::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */ }
  @media (min-width: 800px) {
    .favorites::-webkit-scrollbar {
      display: unset; } }

.frequent::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */ }
  @media (min-width: 800px) {
    .frequent::-webkit-scrollbar {
      display: unset; } }

.payment-failed-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0px 10px;
  justify-content: space-around;
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  background: #914545;
  color: white;
  text-align: center;
  z-index: 5;
  opacity: 0.8;
  cursor: pointer;
  transition: 500ms; }
  .payment-failed-outer .dismiss {
    height: 20px;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1); }
  @media (min-width: 630px) {
    .payment-failed-outer {
      width: 60%;
      opacity: 0.7; } }
  @media (min-width: 1000px) {
    .payment-failed-outer {
      width: 40%;
      margin-left: 200px; } }
  .payment-failed-outer:hover {
    opacity: 1; }

.explore-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 10px 90px 10px; }
  @media (min-width: 1000px) {
    .explore-container {
      padding: 50px 0 0 200px; } }
  @media (max-height: 440px) {
    .explore-container {
      padding-left: 100px; } }
  @media (max-height: 440px) and (max-width: 1000px) {
    .explore-container {
      padding-left: 100px;
      padding-bottom: 0; } }
  .explore-container .top {
    text-align: center;
    color: #a89cd9; }
    @media (min-width: 1000px) {
      .explore-container .top {
        width: 90%;
        text-align: left; } }
    .explore-container .top .filter-title {
      font-size: 1.1rem;
      font-weight: 700;
      letter-spacing: 1.32px;
      margin-bottom: 8px; }
      @media (min-width: 1000px) {
        .explore-container .top .filter-title {
          font-size: 1.5rem; } }
    .explore-container .top .filter-subtitle {
      font-size: 0.9rem;
      margin-bottom: 15px; }
      @media (min-width: 1000px) {
        .explore-container .top .filter-subtitle {
          font-size: 1.1rem; } }
  .explore-container .filter {
    background: #353849;
    border-radius: 20px;
    height: auto;
    min-height: 150px;
    width: 100%;
    margin: 10px 0;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative; }
    @media (min-width: 600px) {
      .explore-container .filter {
        width: 80%; } }
    @media (min-width: 1000px) {
      .explore-container .filter {
        width: 90%;
        padding: 25px 25px; } }
    @media (max-height: 440px) and (max-width: 1000px) {
      .explore-container .filter {
        width: 80%; } }
  .explore-container .results {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: #353849;
    border-radius: 20px;
    height: auto;
    min-height: 311px;
    width: 100%;
    margin: 10px 0;
    padding: 20px 10px 10px 10px; }
    @media (min-width: 600px) {
      .explore-container .results {
        width: 80%; } }
    @media (min-width: 700px) {
      .explore-container .results {
        display: grid;
        grid-template: 275px / 275px 275px;
        justify-items: center; } }
    @media (min-width: 1000px) {
      .explore-container .results {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 20px;
        padding-top: 30px;
        min-height: 316.4px;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
        overflow-x: scroll;
        overflow-y: hidden; } }
    @media (max-height: 440px) and (max-width: 1000px) {
      .explore-container .results {
        width: 80%; } }
  .explore-container .tag {
    cursor: pointer;
    background: #272A3D;
    max-height: 33.33px;
    color: #a89cd9;
    border-radius: 25.9px;
    padding: 5px 15px;
    margin: 5px 3px;
    line-height: 1.3;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 1.21px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media (min-width: 600px) {
      .explore-container .tag {
        max-height: 40.33px;
        padding: 5px 20px;
        margin: 5px 5px; } }
  .explore-container .selected-tag {
    background: white;
    color: #5f51b1;
    transition: 500ms; }
  .explore-container .results-title {
    color: #a89cd9;
    top: 235px;
    left: calc(50% - 140px);
    text-align: center;
    position: absolute;
    width: 280px;
    z-index: 1;
    display: none; }
    @media (min-width: 434px) {
      .explore-container .results-title {
        top: 190px; } }
    @media (min-width: 1000px) {
      .explore-container .results-title {
        display: unset;
        font-size: 1.3rem;
        width: 400px;
        left: 10px; } }
  .explore-container .results-title-mobile {
    top: 15px;
    left: calc(50% - 140px);
    text-align: center;
    position: absolute;
    color: #a89cd9; }
    @media (min-width: 1000px) {
      .explore-container .results-title-mobile {
        display: none; } }

.buffer {
  min-width: 5px;
  width: 25px;
  height: 1px;
  margin: 40px 10px 0 10px; }

.learn-container {
  padding-bottom: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 1000px) {
    .learn-container {
      padding-left: 200px;
      padding-bottom: 0; } }
  @media (max-height: 440px) {
    .learn-container {
      padding-left: 100px;
      padding-bottom: 0; } }
  .learn-container h1 {
    color: #a89cd9; }
  @media (min-width: 760px) {
    .learn-container .posts-container {
      display: grid;
      grid-template: 1fr / 1fr 1fr; } }
  @media (min-width: 1000px) {
    .learn-container .posts-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100vw; } }

.journeys-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #272A3D;
  padding: 30px 20px 80px 20px; }
  @media (min-width: 1000px) {
    .journeys-container {
      padding-left: 200px;
      padding-bottom: 0; } }
  @media (max-height: 440px) {
    .journeys-container {
      padding: 10px 0 0 100px; } }
  .journeys-container header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }
    .journeys-container header .title {
      margin-bottom: 10px;
      width: 80vw;
      font-weight: 600;
      font-family: 'Poppins', sans-serif; }
    .journeys-container header .subtitle {
      margin: 0 0 25px 0;
      width: 55vw; }
  .journeys-container main {
    max-width: 1031px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 1000px) {
      .journeys-container main {
        display: grid;
        grid-template: 1fr / 1fr; } }
    @media (min-width: 1156px) {
      .journeys-container main {
        display: grid;
        grid-template: 1fr / 1fr 1fr; } }

.logged-out-landing-container nav {
  position: relative;
  z-index: 1;
  width: 100%;
  color: #a89cd9;
  padding: 0 10px;
  display: flex;
  justify-content: space-between; }
  @media (min-width: 1000px) {
    .logged-out-landing-container nav {
      height: 100px; } }
  .logged-out-landing-container nav .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }
  .logged-out-landing-container nav .logo-image {
    cursor: pointer;
    height: 20px;
    margin-left: 20px; }
  .logged-out-landing-container nav button {
    cursor: pointer;
    font-weight: 550;
    font-size: 0.9rem;
    transition: 500ms;
    height: 37px;
    margin: 9px 0;
    padding: 0 15px;
    border: 1px solid white;
    background: transparent;
    color: white;
    border-radius: 57px; }
    .logged-out-landing-container nav button:focus {
      outline: none; }
    .logged-out-landing-container nav button:hover {
      -webkit-filter: brightness(120%);
              filter: brightness(120%); }
  .logged-out-landing-container nav .nav-right {
    color: white;
    font-size: 0.9rem;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }
    .logged-out-landing-container nav .nav-right .about {
      cursor: pointer;
      font-weight: 300;
      margin-right: 6px; }
      @media (max-width: 600px) {
        .logged-out-landing-container nav .nav-right .about {
          display: none; } }
    .logged-out-landing-container nav .nav-right .lotimus {
      cursor: pointer;
      margin-right: 50px;
      font-weight: 600;
      letter-spacing: 1px; }
      @media (max-width: 600px) {
        .logged-out-landing-container nav .nav-right .lotimus {
          display: none; } }

.logged-out-landing-container .hero {
  background: url(/static/media/background.48efcc14.svg);
  margin-top: -55px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 2px;
  height: 130vh;
  max-height: 738.4px;
  padding: 195px 10%;
  text-align: center;
  color: white;
  font-size: 1.9rem;
  font-weight: 600;
  position: relative; }
  @media (max-height: 320px) {
    .logged-out-landing-container .hero {
      height: 300vh;
      margin-bottom: 50px; } }
  @media (min-height: 370px) and (min-width: 800px) {
    .logged-out-landing-container .hero {
      margin-bottom: 80px; } }
  @media (max-width: 630px) {
    .logged-out-landing-container .hero {
      background: url(/static/media/backgroundMobile.3a20ad2d.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center 2px; } }
  @media (min-width: 1000px) {
    .logged-out-landing-container .hero {
      padding: 330px 10%;
      margin-top: -100px;
      margin-bottom: 50px;
      font-size: 40px; } }
  @media (min-width: 1300px) {
    .logged-out-landing-container .hero {
      max-height: 1000px; } }
  .logged-out-landing-container .hero .never {
    font-weight: 300; }
  .logged-out-landing-container .hero .meditated {
    font-weight: 700;
    letter-spacing: 1.5px; }
  .logged-out-landing-container .hero .before {
    font-weight: 300; }
  .logged-out-landing-container .hero .hero-break {
    height: 5px; }
    @media (min-width: 1000px) {
      .logged-out-landing-container .hero .hero-break {
        margin-bottom: -25px; } }
  .logged-out-landing-container .hero h5 {
    margin: 0;
    display: none;
    font-size: 24px;
    font-weight: 300; }
    @media (min-width: 1000px) {
      .logged-out-landing-container .hero h5 {
        display: unset; } }
  .logged-out-landing-container .hero .down-arrow {
    position: absolute;
    left: calc(50% - 20px);
    top: 70%;
    cursor: pointer; }
    @media (min-width: 1000px) {
      .logged-out-landing-container .hero .down-arrow {
        top: 80%; } }
    @media (min-width: 1300px) {
      .logged-out-landing-container .hero .down-arrow {
        top: 70%; } }

.logged-out-landing-container .hero2 {
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 110vh;
  max-height: 624.8px;
  background: #353849; }
  @media (max-height: 320px) {
    .logged-out-landing-container .hero2 {
      height: 150vh; } }
  @media (min-height: 370px) and (min-width: 800px) {
    .logged-out-landing-container .hero2 {
      height: 130vh; } }
  @media (min-width: 631px) {
    .logged-out-landing-container .hero2 {
      margin-top: -50px; } }
  @media (min-width: 800px) {
    .logged-out-landing-container .hero2 {
      margin-top: -100px; } }
  @media (min-width: 1000px) {
    .logged-out-landing-container .hero2 {
      height: 80vh;
      margin-top: -51px; } }
  .logged-out-landing-container .hero2 main {
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media (min-width: 1000px) {
      .logged-out-landing-container .hero2 main {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row; } }
    @media (min-width: 1000px) {
      .logged-out-landing-container .hero2 main .headphones-container {
        margin-left: 30px; } }
    .logged-out-landing-container .hero2 main .headphones-container .headphones {
      cursor: pointer;
      height: 140px;
      margin: 15px;
      z-index: 1; }
      @media (min-width: 1000px) {
        .logged-out-landing-container .hero2 main .headphones-container .headphones {
          height: 190px; } }
    .logged-out-landing-container .hero2 main .hero-2-text-top {
      font-size: 0.8rem;
      color: white;
      font-weight: 300;
      width: 80%; }
      @media (min-width: 600px) {
        .logged-out-landing-container .hero2 main .hero-2-text-top {
          width: 50.5%; } }
      @media (min-width: 1000px) {
        .logged-out-landing-container .hero2 main .hero-2-text-top {
          font-size: 1rem; } }
      @media (min-width: 1300px) {
        .logged-out-landing-container .hero2 main .hero-2-text-top {
          font-size: 1.3rem; } }
    .logged-out-landing-container .hero2 main .unlock {
      font-weight: 700; }
    .logged-out-landing-container .hero2 main .break {
      height: 20px; }
    .logged-out-landing-container .hero2 main .here {
      font-weight: 700;
      margin-left: 5px;
      text-decoration: underline;
      cursor: pointer; }
  .logged-out-landing-container .hero2 .intro {
    transition: 500ms;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: -120px;
    margin-top: -50px;
    color: #a89cd9;
    position: relative;
    width: 200px;
    height: 100px; }
    @media (min-width: 1000px) {
      .logged-out-landing-container .hero2 .intro {
        margin-left: -90px; } }
    .logged-out-landing-container .hero2 .intro:hover {
      -webkit-filter: brightness(120%);
              filter: brightness(120%); }
    .logged-out-landing-container .hero2 .intro img {
      height: 40px;
      width: 27.6px;
      margin: 0 11px 8.5px 11px; }
    .logged-out-landing-container .hero2 .intro span {
      position: absolute;
      right: -110px;
      top: 39px;
      font-size: 1.2rem;
      letter-spacing: 2px; }
    .logged-out-landing-container .hero2 .intro .intro-length {
      position: relative;
      font-size: 1rem;
      margin-left: -50px;
      letter-spacing: 0; }

.hero3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  height: 110vh;
  max-height: 624.8px;
  margin-top: -100px;
  padding-top: 200px;
  background: url(/static/media/footer.5fdd703b.png);
  background-color: #353849;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-height: 320px) {
    .hero3 {
      height: 140vh; } }
  @media (min-height: 370px) and (min-width: 800px) {
    .hero3 {
      height: 140vh; } }
  @media (min-width: 600px) {
    .hero3 {
      margin-top: -150px;
      padding-top: 250px; } }
  @media (min-width: 1000px) {
    .hero3 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding-top: 200px; } }
  @media (min-width: 1300px) {
    .hero3 {
      max-height: 800px; } }
  .hero3 .phones {
    height: 250px; }
    @media (max-height: 320px) {
      .hero3 .phones {
        height: 200px; } }
    @media (min-height: 370px) and (min-width: 800px) {
      .hero3 .phones {
        height: 200px; } }
    @media (max-width: 600px) {
      .hero3 .phones {
        height: 200px; } }
    @media (min-width: 1000px) {
      .hero3 .phones {
        height: 300px;
        margin-right: 15px; } }
  .hero3 .phones-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: -100px;
    margin-left: 15px; }
    .hero3 .phones-text span {
      color: white;
      font-weight: 700;
      font-size: 1rem; }
      @media (min-width: 600px) {
        .hero3 .phones-text span {
          font-size: 1.5rem; } }
      @media (min-width: 1000px) {
        .hero3 .phones-text span {
          font-size: 2rem; } }
    .hero3 .phones-text button {
      cursor: pointer;
      font-weight: 550;
      transition: 500ms;
      height: 37px;
      margin: 9px 0;
      padding: 0 25px;
      color: white;
      background: transparent;
      border: 1px solid white;
      border-radius: 57px; }
      @media (max-width: 600px) {
        .hero3 .phones-text button {
          margin: 9px 0 30px 0; } }
      .hero3 .phones-text button:focus {
        outline: none; }
      .hero3 .phones-text button:hover {
        -webkit-filter: brightness(120%);
                filter: brightness(120%); }

.dark-bg-landing {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.24);
  z-index: -1;
  top: 0;
  left: 0;
  transition: 300ms;
  opacity: 0; }
  .dark-bg-landing .sign-up-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    min-width: 270px;
    width: 85%;
    max-width: 400px;
    min-height: 365px;
    border-radius: 30px;
    border: solid 1px #707070;
    background-color: #ffffff;
    justify-content: space-evenly;
    padding: 35px 20px; }
    @media (max-height: 300px) {
      .dark-bg-landing .sign-up-modal {
        transform: scale(0.7); } }
    .dark-bg-landing .sign-up-modal .dismiss-sign-up {
      height: 25px;
      position: absolute;
      top: 17px;
      right: 17px;
      cursor: pointer; }
    .dark-bg-landing .sign-up-modal .welcome {
      font-weight: 700;
      font-size: 1.3rem;
      color: #7a70ff; }
    .dark-bg-landing .sign-up-modal .email {
      position: relative;
      min-width: 250.69px;
      display: flex;
      flex-direction: column;
      text-align: left;
      color: #7a70ff;
      font-size: 0.9rem;
      margin-bottom: 10px; }
      .dark-bg-landing .sign-up-modal .email span {
        margin: 0 0 0 10px; }
      .dark-bg-landing .sign-up-modal .email input {
        height: 30px;
        border-radius: 25px;
        border: solid 1px #707070;
        padding: 0 15px; }
        .dark-bg-landing .sign-up-modal .email input:focus {
          outline: none; }
      .dark-bg-landing .sign-up-modal .email .email-invalid {
        color: red;
        font-size: 0.8rem; }
    .dark-bg-landing .sign-up-modal .password {
      position: relative;
      transition: 500ms;
      min-width: 250.69px;
      display: flex;
      flex-direction: column;
      text-align: left;
      color: #7a70ff;
      font-size: 0.9rem;
      margin-bottom: 10px; }
      .dark-bg-landing .sign-up-modal .password span {
        margin: 0 0 0 10px; }
      .dark-bg-landing .sign-up-modal .password input {
        height: 30px;
        border-radius: 25px;
        border: solid 1px #707070;
        padding: 0 35px 0 15px; }
        .dark-bg-landing .sign-up-modal .password input:focus {
          outline: none; }
      .dark-bg-landing .sign-up-modal .password .pass-invalid {
        color: red;
        font-size: 0.7rem;
        max-width: 250.69px; }
      .dark-bg-landing .sign-up-modal .password select {
        border-radius: 25px;
        height: 30px;
        padding: 7px 35px 0 10px;
        color: black;
        overflow: hidden;
        font-size: 0.8rem; }
        .dark-bg-landing .sign-up-modal .password select:focus {
          outline: none; }
    .dark-bg-landing .sign-up-modal .hide {
      opacity: 0;
      height: 0;
      z-index: -1; }
    .dark-bg-landing .sign-up-modal .show {
      opacity: 1;
      height: 100%;
      z-index: unset; }
    .dark-bg-landing .sign-up-modal .buttons {
      margin-top: 20px; }
      .dark-bg-landing .sign-up-modal .buttons .login {
        background: #7a70ff;
        color: white;
        border-radius: 25px;
        border: none;
        margin-right: 5px;
        padding: 10px 18px;
        cursor: pointer; }
        .dark-bg-landing .sign-up-modal .buttons .login:focus {
          outline: none; }
      .dark-bg-landing .sign-up-modal .buttons .create {
        border-radius: 25px;
        background: white;
        color: #7a70ff;
        border: solid 1px #7a70ff;
        margin-left: 5px;
        padding: 10px;
        cursor: pointer; }
        .dark-bg-landing .sign-up-modal .buttons .create:focus {
          outline: none; }

.footer {
  background-color: #353849;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly; }
  .footer a {
    color: white;
    text-decoration: none;
    cursor: pointer; }
  @media (min-width: 600px) {
    .footer {
      display: flex;
      flex-direction: row-reverse;
      height: 300px; } }
  @media (max-width: 599px) {
    .footer {
      justify-content: flex-end;
      padding-bottom: 20px; } }
  .footer .updates {
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .footer .updates span {
      margin-top: 20px; }
    .footer .updates input {
      padding: 0 20px;
      text-align: center;
      color: white;
      background: transparent;
      border: 1px solid white;
      width: 200px;
      height: 45px;
      margin: 10px 0 0 0;
      font-size: 0.9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .footer .updates input:focus {
        outline: none; }
  .footer .logo {
    text-align: center;
    color: white;
    font-size: 0.9rem; }
    @media (max-width: 600px) {
      .footer .logo {
        font-size: 0.7rem; } }
    .footer .logo .image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      color: white;
      font-size: 1.9rem; }
      @media (max-width: 600px) {
        .footer .logo .image {
          font-size: 1.5rem;
          margin: 30px 0 20px 0; } }
      .footer .logo .image .logo-text {
        -webkit-filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%);
                filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%);
        height: 20px;
        margin-bottom: 10px; }
      .footer .logo .image img {
        height: 150px; }
  .footer .contact {
    color: white;
    cursor: pointer; }
    @media (min-width: 600px) {
      .footer .contact {
        margin-top: -32px; } }
    @media (min-width: 1000px) {
      .footer .contact {
        margin-top: -30px; } }

.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url(/static/media/aboutBackground.81ef9dd7.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 55px 0;
  margin-top: -55px; }
  @media (min-width: 1000px) {
    .about-container {
      margin-top: -100px;
      padding: 100px 0 15% 0; } }
  .about-container p {
    width: 75%; }
    @media (min-width: 1000px) {
      .about-container p {
        width: 55%; } }
  .about-container .about-title {
    font-size: 1.3rem;
    font-weight: 700; }
    @media (min-width: 1000px) {
      .about-container .about-title {
        font-size: 2rem; } }
  .about-container .about-change {
    font-size: 1rem;
    font-weight: 700; }
    @media (min-width: 1000px) {
      .about-container .about-change {
        font-size: 1.2rem; } }
  .about-container .about-journey {
    font-size: 1rem;
    font-weight: 700; }
    @media (min-width: 1000px) {
      .about-container .about-journey {
        font-size: 1.2rem; } }

.visible {
  opacity: 1;
  transition: 300ms;
  z-index: 3; }

.eyeball {
  position: absolute;
  cursor: pointer;
  right: 10px;
  bottom: 15px;
  height: 20px; }

.bottom-start {
  cursor: pointer;
  font-weight: 550;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  height: 40px;
  margin: 25px 0 0 0;
  padding: 0 25px;
  border: none;
  border-radius: 57px;
  color: #323749;
  background: white; }
  @media (min-width: 1000px) {
    .bottom-start {
      margin: 40px 0 0 0; } }
  .bottom-start:focus {
    outline: none; }

.forgot {
  color: #7a70ff;
  cursor: pointer;
  margin-top: 10px; }

.forgot-pass {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  color: #7a70ff;
  font-size: 1.1rem;
  height: 100%;
  width: 100%;
  margin-bottom: -20px; }
  .forgot-pass .back {
    height: 25px;
    position: absolute;
    left: 0;
    top: -20px;
    cursor: pointer; }
    .forgot-pass .back:focus {
      outline: none; }
  .forgot-pass .inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .forgot-pass .inputs input {
      padding: 0 20px;
      text-align: center;
      border: 1px solid #707070;
      border-radius: 27px;
      width: 240px;
      height: 45px;
      margin: 15px 0 30px 0;
      font-size: 0.9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .forgot-pass .inputs input:focus {
        outline: none; }
    .forgot-pass .inputs button {
      text-decoration: none;
      border: none;
      color: white;
      border-radius: 27px;
      background: #7a70ff;
      padding: 10px 15px;
      cursor: pointer; }
  .forgot-pass .msg {
    height: 30px; }

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 5;
  padding: 0 10px;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  background: #272A3D;
  height: 90px;
  width: 100%;
  box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.274);
  background: #353849; }
  @media (min-width: 1000px) {
    .nav-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      left: 0;
      height: 100%;
      width: 200px;
      padding-top: 35px;
      box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.212); }
      .nav-container div {
        margin: 20px 0; } }
  @media (max-height: 440px) {
    .nav-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 0;
      height: 100%;
      width: 100px;
      z-index: 2; }
      .nav-container div {
        margin: 30px 0; }
      .nav-container img {
        display: none; } }
  @media (max-height: 440px) and (min-width: 1000px) {
    .nav-container img {
      display: unset; }
    .nav-container span {
      display: none; } }
  .nav-container .logo {
    display: none; }
    @media (min-width: 1000px) {
      .nav-container .logo {
        position: absolute;
        top: 20px;
        left: 45px;
        display: unset !important; }
        .nav-container .logo .logo-image {
          height: 75px;
          margin-bottom: -10px; }
        .nav-container .logo .logo-text {
          -webkit-filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%);
                  filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%);
          top: 75px;
          left: 10px;
          height: 20px;
          position: absolute; } }
    @media (max-height: 645px) {
      .nav-container .logo {
        display: none !important; } }
  .nav-container div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #848484;
    font-weight: 400;
    font-size: 0.8rem;
    width: 0;
    padding: 0;
    cursor: pointer; }
  .nav-container .container-clicked {
    color: #a89cd9;
    font-weight: 700; }
  .nav-container .journey-container {
    position: relative; }
  .nav-container img {
    position: absolute;
    top: 25px;
    transition: 500ms;
    height: 30px;
    margin-bottom: 5px;
    z-index: 2; }
    @media (min-width: 1000px) {
      .nav-container img {
        height: 45px;
        top: unset; } }
    @media (max-height: 440px) {
      .nav-container img {
        height: 35px; } }
  .nav-container span {
    transition: 250ms;
    margin-bottom: -40px;
    font-family: 'Poppins', sans-serif; }

.selected {
  position: absolute;
  cursor: pointer;
  z-index: 3;
  transition: 500ms; }

.not-selected {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  transition: 500ms; }

@media (min-width: 1000px) {
  .you-container-i {
    position: absolute;
    top: 26%; } }

@media (max-height: 645px) {
  .you-container-i {
    top: 21%; } }

@media (max-height: 440px) {
  .you-container-i {
    top: 20%; } }

@media (min-width: 1000px) {
  .y {
    position: absolute;
    top: 26%; } }

@media (max-height: 645px) {
  .y {
    top: 21%; } }

@media (max-height: 440px) {
  .y {
    top: 20%; } }

@media (min-width: 1000px) {
  .journeys-container-i {
    position: absolute;
    top: 42.5%; } }

@media (max-height: 645px) {
  .journeys-container-i {
    top: 37.5%; } }

@media (max-height: 440px) {
  .journeys-container-i {
    top: 36.5%; } }

@media (min-width: 1000px) {
  .j {
    position: absolute;
    top: 42.5%; } }

@media (max-height: 645px) {
  .j {
    top: 37.5%; } }

@media (max-height: 440px) {
  .j {
    top: 36.5%; } }

@media (min-width: 1000px) {
  .explore-container-i {
    position: absolute;
    top: 59.5%; } }

@media (max-height: 645px) {
  .explore-container-i {
    top: 54.5%; } }

@media (max-height: 440px) {
  .explore-container-i {
    top: 52%; } }

@media (min-width: 1000px) {
  .e {
    position: absolute;
    top: 59.5%; } }

@media (max-height: 645px) {
  .e {
    top: 54.5%; } }

@media (max-height: 440px) {
  .e {
    top: 52%; } }

@media (min-width: 1000px) {
  .learn-container-i {
    position: absolute;
    top: 76%; } }

@media (max-height: 645px) {
  .learn-container-i {
    top: 71%; } }

@media (max-height: 440px) {
  .learn-container-i {
    top: 68.5%; } }

@media (min-width: 1000px) {
  .l {
    position: absolute;
    top: 76%; } }

@media (max-height: 645px) {
  .l {
    top: 71%; } }

@media (max-height: 440px) {
  .l {
    top: 68.5%; } }

@media (min-width: 1000px) {
  .journeys-title {
    margin-top: 30px; } }

@media (min-width: 1000px) {
  .explore-title {
    margin-top: 30px; } }

@media (min-width: 1000px) {
  .learn-title {
    margin-top: 30px; } }

@media (min-width: 1000px) {
  .you-title {
    margin-top: 30px; } }

.player-container {
  width: auto;
  padding-top: 60px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start; }
  @media (min-width: 1000px) {
    .player-container {
      padding-left: 200px;
      padding-bottom: 0; } }
  @media (max-height: 440px) {
    .player-container {
      padding-left: 100px;
      padding-bottom: 0; } }
  .player-container nav {
    transition: 500ms;
    font-family: 'Poppins', sans-serif;
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: fixed;
    background: #272A3D;
    height: 30px;
    padding: 0 10px;
    border-radius: 30px;
    left: 10px;
    top: 15px;
    color: #a89cd9;
    font-weight: 500; }
    @media (min-width: 1000px) {
      .player-container nav {
        left: 250px; } }
    @media (max-height: 440px) {
      .player-container nav {
        left: 150px; } }
    .player-container nav:hover {
      -webkit-filter: brightness(150%);
              filter: brightness(150%); }
    .player-container nav .back-arrow {
      height: 20px;
      margin-right: 10px; }
  .player-container .circle {
    height: 100px;
    width: 100px;
    box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.192);
    border-radius: 50%; }
  .player-container .player {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.192);
    padding: 20px;
    min-height: 330px;
    width: 300px;
    border-radius: 32.7px;
    background: #353849; }
    @media (min-width: 760px) {
      .player-container .player {
        transition: 700ms;
        min-width: 750px;
        align-items: flex-start;
        padding: 0 75px;
        background-repeat: no-repeat;
        background-size: cover;
        background: linear-gradient(rgba(15, 26, 64, 0.7), rgba(15, 26, 64, 0.7)), var(--background-url); } }
    @media (max-height: 440px) and (min-width: 760px) {
      .player-container .player {
        min-height: 300px;
        min-width: 650px;
        padding: 0 40px;
        transition: 700ms; } }
    .player-container .player .title {
      font-family: 'Poppins-light';
      margin: 0 0 7px 0;
      font-size: 2rem;
      font-weight: 450;
      text-align: center;
      width: 90%;
      line-height: 30px; }
      @media (min-width: 760px) {
        .player-container .player .title {
          text-align: left;
          width: 50%; } }
    .player-container .player .length {
      margin: -10px 0 10px 0;
      color: #a89cd9;
      font-size: 0.8rem;
      font-weight: 500; }
      @media (min-width: 760px) {
        .player-container .player .length {
          margin: -30px 0 30px 0; } }
    .player-container .player .description {
      display: none;
      color: rgba(255, 255, 255, 0.658);
      font-weight: 400;
      font-size: 0.9rem; }
      @media (min-width: 760px) {
        .player-container .player .description {
          display: unset;
          flex-wrap: wrap;
          width: 40%;
          margin: -40px 0 0 0;
          line-height: 20px; } }
    .player-container .player .controls-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      @media (min-width: 760px) {
        .player-container .player .controls-container {
          position: absolute;
          right: 75px; } }
      @media (max-height: 440px) {
        .player-container .player .controls-container {
          right: 40px; } }
      .player-container .player .controls-container .restart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 3px 12px;
        border: 2px solid white;
        border-radius: 27px;
        color: white;
        cursor: pointer;
        letter-spacing: 1px;
        font-size: .9rem;
        margin-top: 5px; }
        .player-container .player .controls-container .restart-container .restart {
          margin-right: 10px; }
    .player-container .player .controls {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row; }
      .player-container .player .controls .back-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        color: rgba(255, 255, 255, 0.808);
        font-weight: 300;
        font-size: 0.8rem;
        cursor: pointer;
        transition: 500ms; }
        .player-container .player .controls .back-container .back {
          border-radius: 50%;
          transition: 500ms;
          margin-bottom: 10px;
          height: 30px; }
          .player-container .player .controls .back-container .back:hover {
            box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.178); }
      .player-container .player .controls .forward-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        color: rgba(255, 255, 255, 0.808);
        font-weight: 300;
        font-size: 0.8rem;
        cursor: pointer;
        transition: 500ms; }
        .player-container .player .controls .forward-container .forward {
          border-radius: 50%;
          transition: 500ms;
          margin-bottom: 10px;
          height: 30px; }
          .player-container .player .controls .forward-container .forward:hover {
            box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.178); }
      .player-container .player .controls .circle {
        margin: 0 25px; }
      .player-container .player .controls .play {
        cursor: pointer;
        transition: 500ms;
        position: absolute;
        height: 66px;
        left: calc(50% - 35px);
        top: 17px;
        border-radius: 100%; }
        .player-container .player .controls .play:hover {
          -webkit-filter: brightness(120%);
                  filter: brightness(120%); }
    .player-container .player .current-time {
      color: rgba(255, 255, 255, 0.808);
      font-weight: 300;
      margin-top: 5px;
      margin-right: 5px; }
    .player-container .player .audio-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-top: 20px; }
      .player-container .player .audio-toggle .all-audio-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        transition: 300ms;
        margin-right: -10px;
        padding: 6px 30px 6px 15px;
        border-radius: 15px;
        background: #272A3D;
        color: #cc83c8;
        font-weight: 500;
        font-size: 0.8rem; }
        .player-container .player .audio-toggle .all-audio-container span {
          margin-right: -5px; }
        .player-container .player .audio-toggle .all-audio-container .all-audio {
          height: 20px;
          margin-right: 7px; }
      .player-container .player .audio-toggle .all-audio-container-selected {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        z-index: 1;
        transition: 300ms;
        margin-right: -10px;
        padding: 6px 30px 6px 15px;
        border-radius: 15px;
        background: white;
        color: #cc83c8;
        font-weight: 500;
        font-size: 0.8rem; }
        .player-container .player .audio-toggle .all-audio-container-selected span {
          margin-right: -5px; }
        .player-container .player .audio-toggle .all-audio-container-selected .all-audio {
          height: 20px;
          margin-right: 7px; }
      .player-container .player .audio-toggle .only-music-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        transition: 300ms;
        margin-left: -10px;
        padding: 6px 20px 6px 25px;
        border-radius: 15px;
        background: #272A3D;
        color: #cc83c8;
        font-weight: 500;
        font-size: 0.8rem; }
      .player-container .player .audio-toggle .only-music-container-selected {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        z-index: 1;
        transition: 300ms;
        margin-left: -10px;
        padding: 6px 20px 6px 25px;
        border-radius: 15px;
        background: white;
        color: #cc83c8;
        font-weight: 500;
        font-size: 0.8rem; }
      .player-container .player .audio-toggle .only-music {
        height: 20px;
        margin-right: 7px; }

.player-listened-show {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  opacity: 100;
  transition: 700ms;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(rgba(15, 26, 64, 0.7), rgba(15, 26, 64, 0.7)), var(--background-url);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  min-height: 330px;
  width: 300px;
  border-radius: 32.7px; }
  @media (min-width: 760px) {
    .player-listened-show {
      min-width: 750px;
      padding: 0 75px; } }
  @media (max-height: 440px) and (min-width: 760px) {
    .player-listened-show {
      min-width: 650px;
      padding: 0 40px; } }
  .player-listened-show .check {
    position: absolute;
    height: 100px;
    top: -15px;
    left: calc(50% - 50px); }
  .player-listened-show .you-are {
    color: white;
    font-weight: 500;
    font-size: 1.1rem; }
  .player-listened-show .level {
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px; }
  .player-listened-show .relisten {
    cursor: pointer;
    border: 1px solid white;
    border-radius: 30px;
    color: white;
    font-size: 0.8rem;
    letter-spacing: 1px;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }
    .player-listened-show .relisten .relisten-arrow {
      height: 15px;
      margin-right: 10px;
      display: none; }
      @media (min-width: 760px) {
        .player-listened-show .relisten .relisten-arrow {
          display: unset; } }
      @media (max-height: 440px) and (min-width: 760px) {
        .player-listened-show .relisten .relisten-arrow {
          display: unset; } }
  .player-listened-show .next {
    position: absolute;
    width: 300px;
    height: 105px;
    left: calc(50% - 150px);
    bottom: -15px;
    background: white;
    color: #7a70ff;
    border-radius: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 5px; }
    .player-listened-show .next .text {
      margin-left: -10px; }
    .player-listened-show .next .next-track-image {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      object-fit: cover;
      opacity: 100 !important; }
    .player-listened-show .next p {
      margin: 0;
      font-size: 1rem; }
    .player-listened-show .next .track-name {
      font-size: 1.2rem;
      font-weight: 700; }
    .player-listened-show .next .next-arrow {
      height: 20px;
      margin-bottom: -20px; }

.player-listened-hide {
  opacity: 0;
  transition: 700ms;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(rgba(15, 26, 64, 0.7), rgba(15, 26, 64, 0.7)), var(--background-url);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  min-height: 330px;
  width: 300px;
  border-radius: 32.7px; }
  @media (min-width: 760px) {
    .player-listened-hide {
      min-width: 750px;
      padding: 0 75px; } }
  @media (max-height: 440px) and (min-width: 760px) {
    .player-listened-hide {
      min-width: 650px;
      padding: 0 40px; } }
  .player-listened-hide .check {
    position: absolute;
    height: 100px;
    top: -15px;
    left: calc(50% - 50px); }
  .player-listened-hide .you-are {
    color: white;
    font-weight: 500;
    font-size: 1.1rem; }
  .player-listened-hide .level {
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px; }
  .player-listened-hide .relisten {
    cursor: pointer;
    border: 1px solid white;
    border-radius: 30px;
    color: white;
    font-size: 0.8rem;
    letter-spacing: 1px;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }
    .player-listened-hide .relisten .relisten-arrow {
      height: 15px;
      margin-right: 10px;
      display: none; }
      @media (min-width: 760px) {
        .player-listened-hide .relisten .relisten-arrow {
          display: unset; } }
      @media (max-height: 440px) and (min-width: 760px) {
        .player-listened-hide .relisten .relisten-arrow {
          display: unset; } }
  .player-listened-hide .next {
    position: absolute;
    width: 300px;
    height: 105px;
    left: calc(50% - 150px);
    bottom: -15px;
    background: white;
    color: #7a70ff;
    border-radius: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 5px; }
    .player-listened-hide .next .text {
      margin-left: -10px; }
    .player-listened-hide .next .next-track-image {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      object-fit: cover; }
    .player-listened-hide .next p {
      margin: 0;
      font-size: 1rem; }
    .player-listened-hide .next .track-name {
      font-size: 1.2rem;
      font-weight: 700; }
    .player-listened-hide .next .next-arrow {
      height: 20px;
      margin-bottom: -20px; }

.tracks-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: scroll;
  height: 85vh;
  padding: 20px 20px 15px 20px; }
  @media (min-width: 1000px) {
    .tracks-list-container {
      padding: 85px 90px 0 290px;
      height: 100vh;
      display: grid;
      justify-items: center;
      grid-template: 1fr / 1fr 1fr; } }
  @media (min-width: 1240px) {
    .tracks-list-container {
      grid-template: 1fr / 1fr 1fr 1fr; } }
  @media (min-width: 1480px) {
    .tracks-list-container {
      grid-template: 1fr / 1fr 1fr 1fr 1fr; } }
  @media (min-width: 1570px) {
    .tracks-list-container {
      padding: 85px 6% 0 calc(6% + 200px); } }
  @media (max-height: 440px) {
    .tracks-list-container {
      padding: 0 0 0 100px;
      height: 100vh; } }
  .tracks-list-container nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 1;
    cursor: pointer;
    background: #272A3D;
    height: 30px;
    padding: 0 10px;
    border-radius: 30px;
    transition: 500ms;
    position: fixed;
    left: 10px;
    top: 15px;
    color: #a89cd9;
    font-weight: 500; }
    @media (min-width: 1000px) {
      .tracks-list-container nav {
        left: 250px; } }
    @media (max-height: 440px) {
      .tracks-list-container nav {
        left: 150px; } }
    .tracks-list-container nav:hover {
      -webkit-filter: brightness(150%);
              filter: brightness(150%); }
    .tracks-list-container nav .back-arrow {
      height: 20px;
      margin-right: 10px; }
  .tracks-list-container .loading-results {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media (min-width: 1000px) {
      .tracks-list-container .loading-results {
        width: 80vw; } }

.tracks-list-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */ }

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: #272A3D; }
  .not-found .upper-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 45px; }
    .not-found .upper-content .stay-calm {
      color: #a89cd9;
      font-weight: 400;
      font-size: 2.5rem; }
    .not-found .upper-content .face {
      margin-top: 20px;
      height: 90px; }
    .not-found .upper-content .oops {
      margin: 20px 0;
      max-width: 200px;
      text-align: center;
      color: #848484; }
  .not-found .home {
    cursor: pointer;
    background: #272A3D;
    color: #e0e0e0;
    border: 2px white solid;
    border-radius: 30px;
    height: 40px;
    width: 120px; }
    .not-found .home:focus {
      outline: none; }
  @media (max-height: 415px) {
    .not-found .oops {
      margin-bottom: 0; }
    .not-found .home {
      margin-bottom: 20px; } }

.track-outer-container {
  height: 350px;
  width: 260px;
  position: relative; }
  @media (min-height: 700px) {
    .track-outer-container {
      height: 430px; } }
  @media (min-width: 1000px) {
    .track-outer-container {
      margin-bottom: 65px; } }
  @media (max-width: 1000px) {
    .track-outer-container {
      margin: 10px; } }
  .track-outer-container .lock {
    position: absolute;
    top: -20px;
    left: 10px;
    height: 45px;
    z-index: 1;
    cursor: pointer; }
  .track-outer-container .heart {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 30px;
    height: 25px;
    z-index: 1;
    transition: 500ms; }
  .track-outer-container .heart-filled {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 30px;
    height: 25px;
    z-index: 2;
    transition: 500ms; }
  .track-outer-container .not-filled {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 30px;
    height: 25px;
    z-index: 0;
    transition: 500ms; }
  .track-outer-container .play {
    position: absolute;
    transition: 500ms;
    cursor: pointer;
    height: 75px;
    bottom: -37px;
    left: calc(50% - 37.5px);
    border-radius: 50%;
    box-shadow: 5px 5px 26px rgba(0, 0, 0, 0.26); }
    .track-outer-container .play:hover {
      -webkit-filter: brightness(120%);
              filter: brightness(120%); }
  .track-outer-container .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    background: linear-gradient(rgba(15, 26, 64, 0.5), rgba(15, 26, 64, 0.5));
    border-radius: 32.7px; }
    .track-outer-container .content .title {
      font-size: 2.3rem;
      text-align: center;
      line-height: 35px;
      width: 65%;
      margin-bottom: 10px;
      margin-top: 10px;
      font-family: 'Poppins-light';
      color: white; }
    .track-outer-container .content .listened {
      margin-top: 30px;
      height: 35px; }
    .track-outer-container .content .benefits {
      color: white;
      font-size: 0.9rem;
      margin: -10px 0 0 0;
      padding: 0 0 0 20px; }
      .track-outer-container .content .benefits .ben-1 {
        margin: 27px 0 2px 0;
        font-weight: 400; }
      .track-outer-container .content .benefits .ben-2 {
        margin: 2px 0;
        font-weight: 400; }
      .track-outer-container .content .benefits .ben-3 {
        margin: 2px 0;
        font-weight: 400; }
    .track-outer-container .content .listened {
      opacity: 1; }
  .track-outer-container .length {
    margin: 0;
    color: white;
    font-size: 0.7rem; }
  .track-outer-container .spacer {
    height: 18.4px;
    width: 33.15px; }
  .track-outer-container .track-container {
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    width: 260px;
    border-radius: 32.7px;
    box-shadow: 5px 5px 26px rgba(0, 0, 0, 0.26); }
    @media (max-height: 535px) {
      .track-outer-container .track-container {
        transform: scale(0.8); } }
    @media (min-height: 700px) {
      .track-outer-container .track-container {
        height: 430px; } }

.locked {
  cursor: pointer; }

.categories-container {
  cursor: pointer; }
  @media (min-width: 1000px) {
    .categories-container .journey {
      background: #3b3d50;
      border-radius: 43px;
      padding: 20px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      transition: 500ms;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: auto;
      height: 250px;
      margin: 10px; }
      .categories-container .journey img {
        height: 175px;
        min-width: 174.23px; }
      .categories-container .journey:hover {
        -webkit-filter: brightness(120%);
                filter: brightness(120%); }
      .categories-container .journey .subtitle {
        width: 200px; } }

@media (min-width: 1000px) {
  .coming-soon-outer-container .journey {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: auto;
    height: 250px;
    margin: 10px; }
    .coming-soon-outer-container .journey .coming-soon-image {
      height: 160px; }
    .coming-soon-outer-container .journey .subtitle {
      width: 200px; } }

.journey {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 370px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 50px; }
  .journey img {
    height: 125px;
    min-width: 124.45px; }

.title {
  color: #a89cd9;
  width: 100px;
  font-weight: 350;
  font-size: 1.5rem;
  line-height: 25px;
  font-family: 'Poppins', sans-serif; }

.coming-soon-title {
  color: grey;
  width: 100px;
  font-weight: 350;
  font-size: 1.5rem;
  line-height: 25px; }

.subtitle {
  color: white;
  font-weight: 300;
  font-size: 0.9rem;
  width: 182px;
  line-height: 20px; }
  @media (min-width: 800px) {
    .subtitle {
      width: 300px; } }

.coming-soon-image {
  height: 110px;
  margin-right: 8px;
  margin-left: 5px; }

.bonus-title {
  color: white;
  margin: 35px 0 5px 0; }

.bonus-tracks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 760px) {
    .bonus-tracks {
      display: grid;
      grid-template: 1fr / 1fr 1fr; } }
  @media (min-width: 1155px) {
    .bonus-tracks {
      display: grid;
      grid-template: 1fr / 1fr 1fr 1fr; } }
  .bonus-tracks .bonus-track {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.192);
    color: #a89cd9;
    align-items: flex-start;
    border-radius: 32.7px;
    height: 110px;
    width: 230px;
    padding: 20px 50px 20px 20px;
    margin: 10px 10px 10px -30px;
    position: relative;
    background: #353849; }
    @media (min-width: 760px) {
      .bonus-tracks .bonus-track {
        margin: 20px; } }
    .bonus-tracks .bonus-track .exercise-title {
      line-height: 30px;
      font-weight: 300;
      font-size: 1.5rem;
      margin: 0;
      font-family: 'Poppins-light'; }
    .bonus-tracks .bonus-track span {
      font-size: 0.7rem; }
    .bonus-tracks .bonus-track img {
      position: absolute;
      cursor: pointer;
      transition: 500ms;
      height: 75px;
      right: -30px;
      top: calc(50% - 37.5px);
      border-radius: 50%;
      box-shadow: 5px 5px 26px rgba(0, 0, 0, 0.26); }
      .bonus-tracks .bonus-track img:hover {
        -webkit-filter: brightness(120%);
                filter: brightness(120%); }

.commentary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  background: #353849;
  border-radius: 26px;
  color: #a89cd9;
  font-size: .8rem;
  font-weight: 500;
  margin: 10px 0 30px -7px;
  width: 190px;
  height: 40px; }
  @media (min-width: 760px) {
    .commentary {
      margin-left: 45px; } }
  .commentary img {
    height: 17px;
    width: 13.5px;
    margin: 0 5.8px 3px 5.8px; }

.blog-post-container {
  transition: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 280px;
  margin-bottom: 30px; }
  @media (min-width: 760px) {
    .blog-post-container {
      margin: 30px 30px; } }
  @media (min-width: 1000px) {
    .blog-post-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 600px;
      margin: 15px 0; } }
  .blog-post-container:hover {
    -webkit-filter: brightness(120%);
            filter: brightness(120%); }
  .blog-post-container img {
    object-fit: cover;
    height: 172.45px;
    width: 280px;
    z-index: 1; }
    @media (min-width: 1000px) {
      .blog-post-container img {
        height: 172.45px;
        min-width: 210px; } }
  .blog-post-container .blog-text {
    margin-top: -30px;
    padding: 30px 15px 20px 15px;
    background: #353849;
    border-radius: 27px;
    max-height: 180px;
    max-width: 280px; }
    @media (min-width: 1000px) {
      .blog-post-container .blog-text {
        min-width: 90%;
        margin: 0 0 0 -30px;
        height: 172.45px;
        padding: 25px 20px 20px 60px; } }
    .blog-post-container .blog-text h3 {
      color: #a89cd9;
      line-height: 20px;
      font-size: 1.1rem;
      margin-bottom: 0;
      max-height: 60px;
      overflow: hidden;
      text-overflow: -o-ellipsis-lastline;
      text-overflow: ellipsis;
      display: block;
      /* autoprefixer: off */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 2.824em; }
      @media (min-width: 1000px) {
        .blog-post-container .blog-text h3 {
          overflow: hidden;
          text-overflow: -o-ellipsis-lastline;
          text-overflow: ellipsis;
          display: block;
          /* autoprefixer: off */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-height: 2.824em;
          font-size: 1.3rem;
          line-height: 30px;
          margin: 0; } }
    .blog-post-container .blog-text p {
      color: white;
      font-size: 0.85rem;
      max-height: 42px;
      max-width: 260px;
      overflow: hidden;
      text-overflow: -o-ellipsis-lastline;
      text-overflow: ellipsis;
      display: block;
      /* autoprefixer: off */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 2.824em; }
      @media (min-width: 1000px) {
        .blog-post-container .blog-text p {
          overflow: hidden;
          text-overflow: -o-ellipsis-lastline;
          text-overflow: ellipsis;
          display: block;
          /* autoprefixer: off */
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-height: 4.236em;
          max-width: 600px;
          margin-top: 10px;
          max-height: 80px; } }

.favorite-track-container {
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.29);
  min-width: 200px;
  width: 200px;
  height: 200px;
  margin: 40px 10px 0 10px;
  text-align: center; }
  .favorite-track-container .content {
    background: linear-gradient(rgba(15, 26, 64, 0.5), rgba(15, 26, 64, 0.5));
    height: 100%;
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .favorite-track-container .lock-favorite {
    position: absolute;
    top: -20px;
    left: 10px;
    height: 45px;
    z-index: 1;
    cursor: pointer; }
  .favorite-track-container .favorite-button {
    height: 25px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer; }
  .favorite-track-container .heart-filled {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
    height: 25px;
    z-index: 2;
    transition: 500ms; }
  .favorite-track-container .not-filled {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
    height: 25px;
    z-index: 0;
    transition: 500ms; }
  .favorite-track-container .favorite-title {
    font-size: 1.3rem;
    font-weight: 600;
    width: 100%; }
  .favorite-track-container .favorite-play {
    height: 40px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: calc(50% - 20px); }

.play-count {
  height: 33px;
  width: 33px;
  background: white;
  color: #5f51b1;
  border-radius: 50%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: -10px;
  right: 10px;
  padding-top: 3px; }

.subscribe-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 90%;
  border-radius: 30px;
  border: solid 1px #707070;
  background-color: #ffffff;
  justify-content: flex-start;
  padding: 30px 15px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: scroll; }
  @media (min-width: 450px) {
    .subscribe-modal-container {
      padding: 30px 40px;
      max-width: 80%; } }
  @media (min-width: 800px) {
    .subscribe-modal-container {
      max-width: 40%;
      max-height: 85%; } }
  @media (max-width: 1000px) {
    .subscribe-modal-container {
      padding: 20px 5% 30px 5%;
      max-height: 80vh;
      margin-top: -70px; } }
  @media (min-width: 1000px) and (max-height: 440px) {
    .subscribe-modal-container {
      margin: 0 0 0 100px; } }
  @media (min-width: 1000px) and (min-height: 440px) {
    .subscribe-modal-container {
      margin: 0 0 0 200px; } }
  .subscribe-modal-container .title-modal {
    color: #a89cd9;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center; }
  .subscribe-modal-container .logo-modal {
    margin-bottom: 30px; }
  .subscribe-modal-container .modal-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 15px; }
    .subscribe-modal-container .modal-buttons-container .confirm {
      background: #a89cd9;
      color: white;
      border: none;
      border-radius: 27px;
      padding: 10px 15px;
      margin-right: 5px;
      cursor: pointer; }
      .subscribe-modal-container .modal-buttons-container .confirm:focus {
        outline: none; }
    .subscribe-modal-container .modal-buttons-container .later {
      margin-left: 5px;
      color: #707070;
      background: white;
      border: #707070 1px solid;
      border-radius: 27px;
      padding: 10px 15px;
      cursor: pointer; }
      .subscribe-modal-container .modal-buttons-container .later:focus {
        outline: none; }
  .subscribe-modal-container .logo-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .subscribe-modal-container .logo-modal .logo-image-modal {
      height: 75px; }
    .subscribe-modal-container .logo-modal .logo-text-modal {
      height: 25px;
      -webkit-filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%);
              filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%); }
  .subscribe-modal-container a {
    color: #a89cd9;
    text-decoration: none;
    display: inline;
    cursor: pointer; }
  .subscribe-modal-container main ul {
    font-size: .9rem;
    margin-left: -20px;
    font-weight: 600; }
    @media (min-width: 450px) {
      .subscribe-modal-container main ul {
        margin-left: 0; } }

.subscribe-modal-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */ }

.subscribe-container {
  padding-bottom: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 800px) {
    .subscribe-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row; } }
  @media (min-width: 1000px) {
    .subscribe-container {
      padding: 0 0 0 200px;
      height: 100vh; } }
  @media (max-height: 440px) {
    .subscribe-container {
      padding-left: 100px; } }
  @media (max-height: 440px) and (max-width: 1000px) {
    .subscribe-container {
      padding-left: 100px;
      padding-bottom: 0; } }
  .subscribe-container .sub {
    position: relative;
    background: #f2f4f2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 20px;
    padding: 30px 20px;
    width: 90%;
    height: 417px;
    max-width: 460px;
    border-radius: 27px; }
    .subscribe-container .sub span {
      text-align: center; }
    .subscribe-container .sub h1 {
      color: #a89cd9; }
    .subscribe-container .sub .sub-title {
      font-size: 1.7rem;
      font-weight: 600; }
    .subscribe-container .sub .sub-subtitle {
      font-size: 1.2rem;
      margin-bottom: 20px; }
    .subscribe-container .sub .savings {
      position: absolute;
      top: 140px;
      height: 30px;
      color: white;
      padding: 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row; }
      .subscribe-container .sub .savings .left {
        width: 0;
        height: 0;
        margin-right: -1px;
        border-top: 26.4px solid #a89cd9;
        border-left: 26.4px solid transparent; }
      .subscribe-container .sub .savings .middle {
        height: 26.4px;
        background: #a89cd9;
        z-index: 1; }
      .subscribe-container .sub .savings .right {
        width: 0;
        height: 0;
        border-top: 26.4px solid #a89cd9;
        border-right: 26.4px solid transparent; }
      .subscribe-container .sub .savings .left2 {
        position: absolute;
        left: 11px;
        width: 0;
        height: 0;
        border-bottom: 26.4px solid #a89cd9;
        border-left: 26.4px solid transparent; }
      .subscribe-container .sub .savings .right2 {
        position: absolute;
        right: 11px;
        width: 0;
        height: 0;
        border-bottom: 26.4px solid #a89cd9;
        border-right: 26.4px solid transparent; }
  .subscribe-container .break {
    background: transparent; }
  .subscribe-container button {
    background: #a89cd9;
    font-size: 1.1rem;
    width: 210px;
    height: 60px;
    cursor: pointer;
    color: white;
    border: none; }

.month button {
  background: transparent;
  color: black;
  border: 2px solid black; }

.payment-container {
  height: 100vh;
  padding-bottom: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 1000px) {
    .payment-container {
      padding: 0 0 0 200px; } }
  @media (max-height: 440px) {
    .payment-container {
      padding-left: 100px; } }
  @media (max-height: 440px) and (max-width: 1000px) {
    .payment-container {
      padding-left: 100px;
      padding-bottom: 0; } }
  .payment-container .payment-inner {
    position: relative;
    width: 90%;
    max-width: 500px;
    background: white;
    border-radius: 27px;
    padding: 30px;
    color: #a89cd9;
    font-size: 1.2rem;
    font-weight: 600; }
    @media (min-width: 760px) {
      .payment-container .payment-inner {
        width: 55%; } }
    .payment-container .payment-inner .loading-results-payment {
      position: absolute;
      top: calc(50% - 37.5px);
      right: calc(50% - 37.5px); }
    .payment-container .payment-inner .StripeElement {
      margin-top: 50px; }
    .payment-container .payment-inner label {
      font-size: 1rem; }
    .payment-container .payment-inner .coupon-container {
      margin-bottom: -35px; }
      .payment-container .payment-inner .coupon-container .coupon-input {
        margin: 0 25px 0 0;
        border: 0px solid;
        height: 20px;
        width: 160px;
        color: rgba(0, 0, 0, 0.473); }
        .payment-container .payment-inner .coupon-container .coupon-input:focus {
          outline: none; }
      .payment-container .payment-inner .coupon-container .redeem {
        padding: 6px 10px; }
    .payment-container .payment-inner .confirm-sub {
      background: #a89cd9;
      color: white;
      border-radius: 27px;
      border: none;
      cursor: pointer;
      padding: 10px 12px;
      margin-right: 15px; }
      .payment-container .payment-inner .confirm-sub:focus {
        outline: none; }
    .payment-container .payment-inner .sub-buttons {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row; }
      .payment-container .payment-inner .sub-buttons .cancel-sub {
        background: transparent;
        color: #a89cd9;
        border-radius: 27px;
        border: 1px solid #707070;
        cursor: pointer;
        padding: 10px 18px;
        margin-left: 15px; }
        .payment-container .payment-inner .sub-buttons .cancel-sub:focus {
          outline: none; }
  .payment-container #card-errors {
    color: red;
    font-size: 0.8rem;
    position: absolute; }

.sign-up-modal-settings {
  width: 90%;
  max-width: 550px;
  margin-bottom: 90px;
  border-radius: 30px;
  border: solid 1px #707070;
  background-color: #ffffff;
  padding: 30px 20px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: scroll; }
  @media (min-width: 1000px) {
    .sign-up-modal-settings {
      margin: 0 0 0 200px;
      width: 45%;
      max-width: 600px; } }
  .sign-up-modal-settings hr {
    margin-left: -20px;
    width: calc(100% + 40px);
    opacity: 0.4; }
  .sign-up-modal-settings h4 {
    margin: 0; }
  .sign-up-modal-settings h5 {
    color: #a89cd9;
    margin: 0; }
  .sign-up-modal-settings span {
    font-size: 0.85rem;
    color: #707070; }
  .sign-up-modal-settings #break {
    height: 10px; }
  .sign-up-modal-settings .back {
    height: 25px;
    position: absolute;
    left: -3px;
    top: -12px;
    cursor: pointer; }
    .sign-up-modal-settings .back:focus {
      outline: none; }
  .sign-up-modal-settings .settings-nav {
    text-align: center;
    position: relative;
    color: #a89cd9;
    font-size: 1.1rem;
    font-weight: 600; }
    .sign-up-modal-settings .settings-nav .logout-button {
      position: absolute;
      left: -5px;
      top: -15px;
      color: #a89cd9;
      border: 1px solid #707070;
      border-radius: 27px;
      background: white;
      padding: 8px 10px;
      cursor: pointer; }
      .sign-up-modal-settings .settings-nav .logout-button:focus {
        outline: none; }
    .sign-up-modal-settings .settings-nav .dismiss-settings {
      position: absolute;
      height: 25px;
      right: -5px;
      top: -15px;
      cursor: pointer; }
  .sign-up-modal-settings .settings-name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between; }
    .sign-up-modal-settings .settings-name .name-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      align-items: flex-start; }
    .sign-up-modal-settings .settings-name .name-right {
      color: #a89cd9;
      cursor: pointer; }
  .sign-up-modal-settings .settings-email {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between; }
    .sign-up-modal-settings .settings-email .email-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      align-items: flex-start; }
      @media (max-width: 400px) {
        .sign-up-modal-settings .settings-email .email-left span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 80%; } }
    .sign-up-modal-settings .settings-email .email-right {
      color: #a89cd9;
      cursor: pointer; }
  .sign-up-modal-settings .settings-subscription {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between; }
    .sign-up-modal-settings .settings-subscription .subscription-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      align-items: flex-start; }
      @media (max-width: 400px) {
        .sign-up-modal-settings .settings-subscription .subscription-left span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; } }
    .sign-up-modal-settings .settings-subscription .subscription-right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      align-items: flex-end; }
      .sign-up-modal-settings .settings-subscription .subscription-right .cancel-sub {
        color: #a89cd9;
        cursor: pointer; }
  .sign-up-modal-settings .settings-billing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between; }
    .sign-up-modal-settings .settings-billing .billing-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      align-items: flex-start; }
    .sign-up-modal-settings .settings-billing .billing-right {
      color: #a89cd9;
      cursor: pointer; }
  .sign-up-modal-settings .settings-contact-home-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-around; }
    .sign-up-modal-settings .settings-contact-home-icon span {
      font-size: 0.9rem;
      text-align: center;
      color: #a89cd9;
      margin-top: 10px;
      cursor: pointer; }
      @media (min-width: 800px) {
        .sign-up-modal-settings .settings-contact-home-icon span {
          font-size: 1rem; } }
      @media (min-width: 1000px) {
        .sign-up-modal-settings .settings-contact-home-icon span {
          font-size: 0.9rem; } }
      @media (min-width: 1200px) {
        .sign-up-modal-settings .settings-contact-home-icon span {
          font-size: 1rem; } }
    .sign-up-modal-settings .settings-contact-home-icon a {
      text-decoration: none;
      text-align: center;
      font-size: 0.9rem;
      margin-top: 10px;
      color: #a89cd9; }
      @media (min-width: 800px) {
        .sign-up-modal-settings .settings-contact-home-icon a {
          font-size: 1rem; } }
      @media (min-width: 1000px) {
        .sign-up-modal-settings .settings-contact-home-icon a {
          font-size: 0.9rem; } }
      @media (min-width: 1200px) {
        .sign-up-modal-settings .settings-contact-home-icon a {
          font-size: 1rem; } }

.sign-up-modal-settings::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */ }

.save {
  border: none;
  background: #a89cd9;
  border-radius: 27px;
  padding: 12px 20px;
  color: white;
  margin-top: 20px;
  cursor: pointer; }
  .save:focus {
    outline: none; }

.edit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .edit a {
    color: #a89cd9;
    text-decoration: none;
    display: inline;
    cursor: pointer;
    font-size: 0.9rem; }
  .edit .title-edit-settings {
    color: #a89cd9;
    margin: 20px 0;
    font-size: 1.1rem;
    font-weight: 600; }
    @media (min-width: 1000px) {
      .edit .title-edit-settings {
        margin: 0 0 20px 0; } }
  .edit .inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    align-items: flex-start;
    position: relative; }
    @media (min-width: 600px) {
      .edit .inputs {
        width: 75%; } }
    .edit .inputs span {
      color: #a89cd9;
      font-size: 0.9rem; }
    .edit .inputs .pass2-outer {
      position: relative;
      width: 100%; }
      .edit .inputs .pass2-outer .eyeball {
        position: absolute;
        top: 11px; }
    .edit .inputs input {
      height: 30px;
      color: #a89cd9;
      width: 100%;
      border-radius: 25px;
      border: solid 1px #707070;
      padding: 0 30px 0 15px;
      margin: 5px 0; }
      .edit .inputs input:focus {
        outline: none; }
    .edit .inputs .pass-invalid {
      font-size: 0.7rem; }

.cancel-sub-container p {
  color: #707070;
  margin: 0;
  font-size: 0.9rem; }

.cancel-sub-container .title-edit-settings {
  color: #a89cd9; }

.cancel-sub-container .cancel {
  color: #a89cd9;
  border: 1px solid #707070;
  border-radius: 27px;
  background: white;
  padding: 12px 8px;
  cursor: pointer;
  margin-left: 5px; }
  .cancel-sub-container .cancel:focus {
    outline: none; }

.cancel-sub-container .do-not-cancel {
  margin-right: 5px;
  padding: 12px 10px; }

.empty-input {
  color: red !important; }

.settings-sub {
  cursor: pointer;
  color: #a89cd9 !important; }

.dark-bg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 3;
  position: fixed;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  transition: 300ms;
  background: rgba(0, 0, 0, 0.24); }
  .dark-bg .add-to-homescreen-modal {
    color: #a89cd9;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    padding: 50px 10px 30px 10px;
    overflow-y: scroll;
    height: 70%;
    width: 90%;
    max-width: 600px;
    z-index: 3;
    background: white;
    border-radius: 27px; }
    .dark-bg .add-to-homescreen-modal div {
      margin-bottom: 5px; }
    .dark-bg .add-to-homescreen-modal img {
      height: 300px; }
    .dark-bg .add-to-homescreen-modal .dismiss {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      height: 25px; }
  @media (min-width: 1000px) {
    .dark-bg .add-to-homescreen-settings {
      margin-left: 200px; } }

.admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 50px; }
  .admin-container h1 {
    color: white;
    margin-top: 0; }
  .admin-container h3 {
    margin-top: 0;
    color: #a89cd9; }
  .admin-container .go-back {
    position: absolute;
    left: 10%; }
  .admin-container .admin-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .admin-container .admin-buttons button {
      margin: 10px; }
  .admin-container .add-track {
    width: 150px;
    height: 50px;
    cursor: pointer;
    font-weight: bold; }
  .admin-container .on {
    background: #a89cd9; }
  .admin-container button {
    cursor: pointer; }

.admin-track-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border-radius: 27px;
  padding: 50px;
  margin: 30px;
  min-width: 624.48px; }
  .admin-track-container span {
    font-weight: bold; }
  .admin-track-container .free-container {
    padding: 20px; }
    .admin-track-container .free-container .free-track-button {
      margin: 5px;
      width: 40px; }
    .admin-track-container .free-container .enabled {
      border: 2px solid #a89cd9;
      background: #a89cd9;
      color: white; }
  .admin-track-container .description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 20px 20px; }
  .admin-track-container .tags-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .admin-track-container .tags-container div {
      font-size: 0.8rem;
      margin: 0 5px; }
    .admin-track-container .tags-container .tags-inner {
      display: flex;
      max-width: 40vw;
      flex-wrap: wrap; }
      .admin-track-container .tags-container .tags-inner .admin-tag {
        padding: 0 3px;
        border: 2px solid transparent;
        cursor: pointer;
        margin: 2px 1px;
        background: rgba(219, 219, 219, 0.637);
        border-radius: 27px; }
        .admin-track-container .tags-container .tags-inner .admin-tag:hover {
          background: #a89cd9;
          border-radius: 27px;
          transition: 300ms;
          color: white; }
      .admin-track-container .tags-container .tags-inner .selected-tag {
        border-radius: 27px;
        background: #a89cd9;
        padding: 0 3px;
        color: white;
        margin: 2px 1px; }
  .admin-track-container .benefits-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px; }
    .admin-track-container .benefits-container input {
      width: 30vw; }
    .admin-track-container .benefits-container span {
      margin-top: 10px; }
  .admin-track-container .categories-container {
    text-align: center;
    margin-top: 20px; }
    .admin-track-container .categories-container .category-span {
      font-size: 1.5rem;
      color: #a89cd9; }
    .admin-track-container .categories-container .categories-inner {
      display: flex; }
      .admin-track-container .categories-container .categories-inner .hide {
        display: none; }
      .admin-track-container .categories-container .categories-inner .admin-category {
        margin: 0 5px;
        padding: 0 5px;
        background: rgba(219, 219, 219, 0.637);
        border-radius: 27px; }
        .admin-track-container .categories-container .categories-inner .admin-category:hover {
          background: #a89cd9;
          transition: 300ms;
          border-radius: 27px;
          color: white; }
  .admin-track-container .submit {
    margin-top: 20px; }
  .admin-track-container .uploads {
    margin-top: 20px;
    text-align: center; }
    .admin-track-container .uploads .s3-upload {
      margin: 10px; }

.admin-tag-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border-radius: 27px;
  min-width: 624.48px;
  padding: 20px;
  margin: 30px; }
  .admin-tag-container span {
    font-weight: bold; }
  .admin-tag-container input {
    margin: 10px; }

.admin-blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border-radius: 27px;
  padding: 20px;
  min-width: 624.48px;
  margin: 30px; }
  .admin-blog-container span {
    font-weight: bold; }
  .admin-blog-container input {
    margin: 10px;
    min-width: 400px; }
  .admin-blog-container .upload {
    display: flex;
    flex-direction: column;
    align-items: center; }

.admin-journey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border-radius: 27px;
  padding: 20px;
  min-width: 624.48px;
  margin: 30px; }
  .admin-journey-container span {
    font-weight: bold; }
  .admin-journey-container input {
    margin: 10px;
    min-width: 350px; }
  .admin-journey-container .coming-soon-container {
    padding: 20px; }
    .admin-journey-container .coming-soon-container .coming-soon-button {
      margin: 5px;
      width: 40px; }
    .admin-journey-container .coming-soon-container .enabled {
      border: 2px solid #a89cd9;
      background: #a89cd9;
      color: white; }
  .admin-journey-container .upload {
    display: flex;
    flex-direction: column;
    align-items: center; }

.admin-bonus-track-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border-radius: 27px;
  padding: 20px;
  min-width: 624.48px;
  margin: 30px; }
  .admin-bonus-track-container span {
    font-weight: bold; }
  .admin-bonus-track-container input {
    margin: 10px; }
  .admin-bonus-track-container .parent-tracks {
    text-align: center; }
    .admin-bonus-track-container .parent-tracks .tracks-container {
      display: flex;
      flex-direction: row;
      max-width: 40vw;
      flex-wrap: wrap; }
      .admin-bonus-track-container .parent-tracks .tracks-container div {
        font-size: 0.8rem;
        margin: 2px 5px;
        cursor: pointer;
        padding: 0 5px;
        background: rgba(219, 219, 219, 0.637);
        border-radius: 27px; }
        .admin-bonus-track-container .parent-tracks .tracks-container div:hover {
          background: #a89cd9;
          transition: 300ms;
          border-radius: 27px;
          color: white; }
  .admin-bonus-track-container .parent-track {
    font-size: 1.5rem;
    color: #a89cd9; }
  .admin-bonus-track-container .uploads {
    display: flex;
    flex-direction: column;
    margin: 20px; }
    .admin-bonus-track-container .uploads input {
      margin: 20px; }

.admin-publish-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border-radius: 27px;
  padding: 20px;
  min-width: 624.48px;
  margin: 30px; }
  .admin-publish-container span {
    font-weight: bold; }
  .admin-publish-container input {
    margin: 10px; }
  .admin-publish-container .tracks {
    text-align: center; }
    .admin-publish-container .tracks .track-to-publish {
      color: #a89cd9; }
    .admin-publish-container .tracks .tracks-container {
      display: flex;
      flex-direction: row;
      max-width: 40vw;
      flex-wrap: wrap;
      margin: 20px; }
      .admin-publish-container .tracks .tracks-container div {
        font-size: 0.8rem;
        margin: 2px 5px;
        cursor: pointer;
        padding: 0 5px;
        background: rgba(219, 219, 219, 0.637);
        border-radius: 27px; }
        .admin-publish-container .tracks .tracks-container div:hover {
          background: #a89cd9;
          transition: 300ms;
          border-radius: 27px;
          color: white; }
      .admin-publish-container .tracks .tracks-container .published {
        background: rgba(4, 170, 4, 0.473); }
      .admin-publish-container .tracks .tracks-container .unpublished {
        background: rgba(255, 34, 34, 0.493); }
  .admin-publish-container .parent-track {
    font-size: 1.5rem;
    color: #a89cd9; }
  .admin-publish-container .uploads {
    display: flex;
    flex-direction: column;
    margin: 20px; }
    .admin-publish-container .uploads input {
      margin: 20px; }

.admin-user-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border-radius: 27px;
  padding: 50px;
  margin: 30px;
  min-width: 624.48px;
  min-height: 336.6px; }
  .admin-user-list-container .choice-container {
    margin-bottom: 20px; }
  .admin-user-list-container .choice {
    padding: 0 10px;
    border: 2px solid transparent;
    cursor: pointer;
    margin: 2px 3px;
    background: rgba(219, 219, 219, 0.637);
    border-radius: 27px; }
    .admin-user-list-container .choice:hover {
      background: #a89cd9;
      border-radius: 27px;
      transition: 300ms;
      color: white; }
  .admin-user-list-container .selected-user-type {
    border-radius: 27px;
    background: #a89cd9;
    color: white;
    margin: 2px 3px; }
  .admin-user-list-container .submit {
    margin-bottom: 20px; }
  .admin-user-list-container .csv {
    font-size: large; }
  .admin-user-list-container .download-user {
    display: flex;
    flex-direction: column;
    align-items: center; }

.admin-user-search-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: whitesmoke;
  border-radius: 27px;
  min-width: 624.48px;
  height: 100vh;
  padding: 20px;
  margin: 30px; }
  .admin-user-search-container .top {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .admin-user-search-container .bottom {
    width: 100%;
    overflow-x: scroll; }
  .admin-user-search-container h3 {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px; }
  .admin-user-search-container span {
    font-weight: bold; }
  .admin-user-search-container input {
    margin: auto;
    margin-bottom: 20px;
    width: 100%; }
  .admin-user-search-container button {
    margin: 0 3px; }
  .admin-user-search-container .user-item {
    margin: 10px 0;
    padding: 5px;
    border-bottom: 1px solid black;
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .admin-user-search-container .confirm {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 25px;
    height: 200px;
    width: 300px;
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 150px); }
    .admin-user-search-container .confirm .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row; }

.reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh; }
  .reset-container .inputs-container {
    width: 70%;
    min-height: 50%;
    max-width: 600px;
    border-radius: 30px;
    border: solid 1px #707070;
    background-color: #ffffff;
    padding: 35px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 500px) {
      .reset-container .inputs-container {
        width: 100%; } }
    .reset-container .inputs-container h3 {
      text-align: center;
      color: #7a70ff; }
    .reset-container .inputs-container .password {
      position: relative;
      transition: 500ms;
      display: flex;
      flex-direction: column;
      text-align: left;
      color: #7a70ff;
      font-size: 0.9rem;
      margin: 10px; }
      .reset-container .inputs-container .password span {
        margin: 0 0 8px 10px; }
      .reset-container .inputs-container .password input {
        height: 30px;
        border-radius: 25px;
        border: solid 1px #707070;
        padding: 0 35px 0 15px; }
        .reset-container .inputs-container .password input:focus {
          outline: none; }
    .reset-container .inputs-container .pass-invalid {
      text-align: center;
      color: red;
      margin-top: 20px; }
  .reset-container .eyeball {
    position: absolute;
    cursor: pointer;
    right: 18px;
    bottom: 15px;
    height: 20px; }
  .reset-container .reset-button {
    background: #7a70ff;
    color: white;
    border-radius: 25px;
    border: none;
    margin: auto;
    width: 150px;
    padding: 10px;
    cursor: pointer; }
    .reset-container .reset-button:focus {
      outline: none; }

.spc {
  height: 25px; }

html {
  box-sizing: border-box;
  background: #272A3D; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.App {
  font-family: 'Poppins-Light';
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.loading-results {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

::-webkit-scrollbar {
  width: 7px;
  height: 7px; }

::-webkit-scrollbar-thumb {
  background: #a89cd9;
  border-radius: 10px; }

::-webkit-scrollbar-thumb:hover {
  background: #9683df; }

