.bonus-title {
    color: white;
    margin: 35px 0 5px 0;
}

.bonus-tracks {
    @media (min-width: 760px) {
        display: grid;
        grid-template: 1fr / 1fr 1fr;
    }
    @media (min-width: 1155px) {
        display: grid;
        grid-template: 1fr / 1fr 1fr 1fr;
    }
    @include flexCenter(column);
    .bonus-track {
        @media (min-width: 760px) {
            margin: 20px;
        }
        @include flexCenter(column);
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.192);
        color: $accent;
        align-items: flex-start;
        border-radius: 32.7px;
        height: 110px;
        width: 230px;
        padding: 20px 50px 20px 20px;
        margin: 10px 10px 10px -30px;
        position: relative;
        background: $bgLight;
        .exercise-title {
            line-height: 30px;
            font-weight: 300;
            font-size: 1.5rem;
            margin: 0;
            font-family: 'Poppins-light';
        }
        span {
            font-size: 0.7rem;
        }
        img {
            position: absolute;
            cursor: pointer;
            transition: 500ms;
            &:hover {
                filter: brightness(120%);
            }
            height: 75px;
            right: -30px;
            top: calc(50% - 37.5px);
            border-radius: 50%;
            box-shadow: 5px 5px 26px rgba(0, 0, 0, 0.26);
        }
    }
}

.commentary {
    @media (min-width: 760px) {
        margin-left: 45px;
    }
    @include flexCenter(row);
    cursor: pointer;
    background: $bgLight;
    border-radius: 26px;
    color: $accent;
    font-size: .8rem;
    font-weight: 500;
    margin: 10px 0 30px -7px;
    width: 190px;
    height: 40px;
    img {
        height: 17px;
        width: 13.5px;
        margin: 0 5.8px 3px 5.8px;
    }
}