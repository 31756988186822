.favorite-track-container {
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    position: relative;
    border-radius: 20px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.29);
    min-width: 200px;
    width: 200px;
    height: 200px;
    margin: 40px 10px 0 10px;
    text-align: center;
    .content {
        background: linear-gradient(rgba(15, 26, 64, 0.5), rgba(15, 26, 64, 0.5));
        height: 100%;
        width: 100%;
        border-radius: 20px;
        @include flexCenter(column);
    }
    .lock-favorite {
        position: absolute;
        top: -20px;
        left: 10px;
        height: 45px;
        z-index: 1;
        cursor: pointer;
    }
    .favorite-button {
        height: 25px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .heart-filled {
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 20px;
        height: 25px;
        z-index: 2;
        transition: 500ms;
    }
    .not-filled {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 20px;
        height: 25px;
        z-index: 0;
        transition: 500ms;
    }
    .favorite-title {
        font-size: 1.3rem;
        font-weight: 600;
        width: 100%;
    }
    .favorite-play {
        height: 40px;
        cursor: pointer;
        position: absolute;
        bottom: 20px;
        right: calc(50% - 20px);
    }
}

.play-count {
    height: 33px;
    width: 33px;
    background: white;
    color: #5f51b1;
    border-radius: 50%;
    font-weight: 700;
    @include flexCenter(column);
    position: absolute;
    top: -10px;
    right: 10px;
    padding-top: 3px;
}