.logged-out-landing-container {
    nav {
        @media (min-width: $desktopBreak) {
            height: 100px;
        }
        position: relative;
        z-index: 1;
        width: 100%;
        color: $accent;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        .logo {
            @include flexCenter(row);
        }
        .logo-image {
            cursor: pointer;
            height: 20px;
            margin-left: 20px;
        }
        button {
            &:focus {
                outline: none;
            }
            &:hover {
                filter: brightness(120%);
            }
            cursor: pointer;
            font-weight: 550;
            font-size: 0.9rem;
            transition: 500ms;
            height: 37px;
            margin: 9px 0;
            padding: 0 15px;
            border: 1px solid white;
            background: transparent;
            color: white;
            border-radius: 57px;
        }
        .nav-right {
            color: white;
            font-size: 0.9rem;
            margin-right: 20px;
            @include flexCenter(row);
            .about {
                @media (max-width: 600px) {
                    display: none;
                }
                cursor: pointer;
                font-weight: 300;
                margin-right: 6px;
            }
            .lotimus {
                @media (max-width: 600px) {
                    display: none;
                }
                cursor: pointer;
                margin-right: 50px;
                font-weight: 600;
                letter-spacing: 1px;
            }
        }
    }
    .hero {
        @media (max-height: 320px) {
            height: 300vh;
            margin-bottom: 50px;
        }
        @media (min-height: 370px) and (min-width: 800px) {
            margin-bottom: 80px;
        }
        @media (max-width: 630px) {
            background: url('../../assets/landing/backgroundMobile.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center 2px;
        }
        @media (min-width: $desktopBreak) {
            padding: 330px 10%;
            margin-top: -100px;
            margin-bottom: 50px;
            font-size: 40px;
        }
        @media (min-width: 1300px) {
            max-height: 1000px;
        }
        background: url('../../assets/landing/background.svg');
        margin-top: -55px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 2px;
        height: 130vh;
        max-height: 738.4px;
        padding: 195px 10%;
        text-align: center;
        color: white;
        font-size: 1.9rem;
        font-weight: 600;
        position: relative;
        .never {
            font-weight: 300;
        }
        .meditated {
            font-weight: 700;
            letter-spacing: 1.5px;
        }
        .before {
            font-weight: 300;
        }
        .hero-break {
            @media (min-width: $desktopBreak) {
                margin-bottom: -25px;
            }
            height: 5px;
        }
        h5 {
            @media (min-width: $desktopBreak) {
                display: unset;
            }
            margin: 0;
            display: none;
            font-size: 24px;
            font-weight: 300;
        }
        .down-arrow {
            @media (min-width: $desktopBreak) {
                top: 80%;
            }
            @media (min-width: 1300px) {
                top: 70%;
            }
            position: absolute;
            left: calc(50% - 20px);
            top: 70%;
            cursor: pointer;
        }
    }
    .hero2 {
        @media (max-height: 320px) {
            height: 150vh;
        }
        @media (min-height: 370px) and (min-width: 800px) {
            height: 130vh;
        }
        @media (min-width: 631px) {
            margin-top: -50px;
        }
        @media (min-width: 800px) {
            margin-top: -100px;
        }
        @media (min-width: $desktopBreak) {
            height: 80vh;
            margin-top: -51px;
        }
        margin-top: -50px;
        @include flexCenter(column);
        height: 110vh;
        max-height: 624.8px;
        background: $bgLight;
        main {
            @media (min-width: $desktopBreak) {
                @include flexCenter(row);
            }
            margin-top: -50px;
            @include flexCenter(column);
            .headphones-container {
                @media (min-width: $desktopBreak) {
                    margin-left: 30px;
                }
                .headphones {
                    @media (min-width: $desktopBreak) {
                        height: 190px;
                    }
                    cursor: pointer;
                    height: 140px;
                    margin: 15px;
                    z-index: 1;
                }
            }
            .hero-2-text-top {
                @media (min-width: 600px) {
                    width: 50.5%;
                }
                @media (min-width: $desktopBreak) {
                    font-size: 1rem;
                }
                @media (min-width: 1300px) {
                    font-size: 1.3rem;
                }
                font-size: 0.8rem;
                color: white;
                font-weight: 300;
                width: 80%;
            }
            .unlock {
                font-weight: 700;
            }
            .break {
                height: 20px;
            }
            .here {
                font-weight: 700;
                margin-left: 5px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .intro {
            @media (min-width: $desktopBreak) {
                margin-left: -90px;
            }
            &:hover {
                filter: brightness(120%);
            }
            transition: 500ms;
            cursor: pointer;
            @include flexCenter(row);
            margin-left: -120px;
            margin-top: -50px;
            color: $accent;
            position: relative;
            width: 200px;
            height: 100px;
            img {
                height: 40px;
                width: 27.6px;
                margin: 0 11px 8.5px 11px;
            }
            span {
                position: absolute;
                right: -110px;
                top: 39px;
                font-size: 1.2rem;
                letter-spacing: 2px;
            }
            .intro-length {
                position: relative;
                font-size: 1rem;
                margin-left: -50px;
                letter-spacing: 0;
            }
        }
    }
}

.hero3 {
    @media (max-height: 320px) {
        height: 140vh;
    }
    @media (min-height: 370px) and (min-width: 800px) {
        height: 140vh;
    }
    @media (min-width: 600px) {
        margin-top: -150px;
        padding-top: 250px;
    }
    @media (min-width: $desktopBreak) {
        @include flexCenter(row);
        padding-top: 200px;
    }
    @media (min-width: 1300px) {
        max-height: 800px;
    }
    @include flexCenter(column-reverse);
    height: 110vh;
    max-height: 624.8px;
    margin-top: -100px;
    padding-top: 200px;
    background: url('../../assets/landing/footer.png');
    background-color: $bgLight;
    background-repeat: no-repeat;
    background-size: cover;
    .phones {
        @media (max-height: 320px) {
            height: 200px;
        }
        @media (min-height: 370px) and (min-width: 800px) {
            height: 200px;
        }
        @media (max-width: 600px) {
            height: 200px;
        }
        @media (min-width: $desktopBreak) {
            height: 300px;
            margin-right: 15px;
        }
        height: 250px;
    }
    .phones-text {
        @include flexCenter(column);
        text-align: center;
        margin-top: -100px;
        margin-left: 15px;
        span {
            @media (min-width: 600px) {
                font-size: 1.5rem;
            }
            @media (min-width: $desktopBreak) {
                font-size: 2rem;
            }
            color: white;
            font-weight: 700;
            font-size: 1rem;
        }
        button {
            @media (max-width: 600px) {
                margin: 9px 0 30px 0;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                filter: brightness(120%);
            }
            cursor: pointer;
            font-weight: 550;
            transition: 500ms;
            height: 37px;
            margin: 9px 0;
            padding: 0 25px;
            color: white;
            background: transparent;
            border: 1px solid white;
            border-radius: 57px;
        }
    }
}

.dark-bg-landing {
    position: fixed;
    @include flexCenter(column);
    backdrop-filter: blur(2px);
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.24);
    z-index: -1;
    top: 0;
    left: 0;
    transition: 300ms;
    opacity: 0;
    .sign-up-modal {
        @media (max-height: 300px) {
            transform: scale(0.7);
        }
        @include flexCenter(column);
        position: relative;
        min-width: 270px;
        width: 85%;
        max-width: 400px;
        min-height: 365px;
        border-radius: 30px;
        border: solid 1px #707070;
        background-color: #ffffff;
        justify-content: space-evenly;
        padding: 35px 20px;
        .dismiss-sign-up {
            height: 25px;
            position: absolute;
            top: 17px;
            right: 17px;
            cursor: pointer;
        }
        .welcome {
            font-weight: 700;
            font-size: 1.3rem;
            color: #7a70ff;
        }
        .email {
            position: relative;
            min-width: 250.69px;
            display: flex;
            flex-direction: column;
            text-align: left;
            color: #7a70ff;
            font-size: 0.9rem;
            margin-bottom: 10px;
            span {
                margin: 0 0 0 10px;
            }
            input {
                height: 30px;
                border-radius: 25px;
                border: solid 1px #707070;
                padding: 0 15px;
                &:focus {
                    outline: none;
                }
            }
            .email-invalid {
                color: red;
                font-size: 0.8rem;
            }
        }
        .password {
            position: relative;
            transition: 500ms;
            min-width: 250.69px;
            display: flex;
            flex-direction: column;
            text-align: left;
            color: #7a70ff;
            font-size: 0.9rem;
            margin-bottom: 10px;
            span {
                margin: 0 0 0 10px;
            }
            input {
                height: 30px;
                border-radius: 25px;
                border: solid 1px #707070;
                padding: 0 35px 0 15px;
                &:focus {
                    outline: none;
                }
            }
            .pass-invalid {
                color: red;
                font-size: 0.7rem;
                max-width: 250.69px;
            }
            select {
                border-radius: 25px;
                height: 30px;
                padding: 7px 35px 0 10px;
                color: black;
                overflow: hidden;
                font-size: 0.8rem;
                &:focus {
                    outline: none;
                }
            }
        }
        .hide {
            opacity: 0;
            height: 0;
            z-index: -1;
        }
        .show {
            opacity: 1;
            height: 100%;
            z-index: unset;
        }
        .buttons {
            margin-top: 20px;
            .login {
                background: #7a70ff;
                color: white;
                border-radius: 25px;
                border: none;
                margin-right: 5px;
                padding: 10px 18px;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
            }
            .create {
                border-radius: 25px;
                background: white;
                color: #7a70ff;
                border: solid 1px #7a70ff;
                margin-left: 5px;
                padding: 10px;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.footer {
    a {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
    background-color: $bgLight;
    @media (min-width: 600px) {
        display: flex;
        flex-direction: row-reverse;
        height: 300px;
    }
    @media (max-width: 599px) {
        justify-content: flex-end;
        padding-bottom: 20px;
    }
    height: 400px;
    @include flexCenter(column);
    justify-content: space-evenly;
    .updates {
        text-align: center;
        color: white;
        @include flexCenter(column);
        span {
            margin-top: 20px;
        }
        input {
            &:focus {
                outline: none;
            }
            padding: 0 20px;
            text-align: center;
            color: white;
            background: transparent;
            border: 1px solid white;
            width: 200px;
            height: 45px;
            margin: 10px 0 0 0;
            font-size: 0.9rem;
            @include flexCenter(column);
        }
    }
    .logo {
        @media (max-width: 600px) {
            font-size: 0.7rem;
        }
        text-align: center;
        color: white;
        font-size: 0.9rem;
        .image {
            @media (max-width: 600px) {
                font-size: 1.5rem;
                margin: 30px 0 20px 0;
            }
            @include flexCenter(column);
            .logo-text {
                filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%);
                height: 20px;
                margin-bottom: 10px;
            }
            cursor: pointer;
            color: white;
            font-size: 1.9rem;
            img {
                height: 150px;
            }
        }
    }
    .contact {
        @media (min-width: 600px) {
            margin-top: -32px;
        }
        @media (min-width: $desktopBreak) {
            margin-top: -30px;
        }
        color: white;
        cursor: pointer;
    }
}

.about-container {
    @media (min-width: $desktopBreak) {
        margin-top: -100px;
        padding: 100px 0 15% 0;
    }
    @include flexCenter(column);
    background: url('../../assets/landing/aboutBackground.svg');
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    padding: 55px 0;
    margin-top: -55px;
    p {
        @media (min-width: $desktopBreak) {
            width: 55%;
        }
        width: 75%;
    }
    .about-title {
        @media (min-width: $desktopBreak) {
            font-size: 2rem;
        }
        font-size: 1.3rem;
        font-weight: 700;
    }
    .about-change {
        @media (min-width: $desktopBreak) {
            font-size: 1.2rem;
        }
        font-size: 1rem;
        font-weight: 700;
    }
    .about-journey {
        @media (min-width: $desktopBreak) {
            font-size: 1.2rem;
        }
        font-size: 1rem;
        font-weight: 700;
    }
}

.visible {
    opacity: 1;
    transition: 300ms;
    z-index: 3;
}

.eyeball {
    position: absolute;
    cursor: pointer;
    right: 10px;
    bottom: 15px;
    height: 20px;
}

.bottom-start {
    @media (min-width: $desktopBreak) {
        margin: 40px 0 0 0;
    }
    &:focus {
        outline: none;
    }
    cursor: pointer;
    font-weight: 550;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    height: 40px;
    margin: 25px 0 0 0;
    padding: 0 25px;
    border: none;
    border-radius: 57px;
    color: #323749;
    background: white;
}