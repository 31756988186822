.track-outer-container {
    @media (min-height: 700px) {
        height: 430px;
    }
    height: 350px;
    width: 260px;
    position: relative;
    @media (min-width: $desktopBreak) {
        margin-bottom: 65px;
    }
    @media (max-width: $desktopBreak) {
        margin: 10px;
    }
    .lock {
        position: absolute;
        top: -20px;
        left: 10px;
        height: 45px;
        z-index: 1;
        cursor: pointer;
    }
    .heart {
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 30px;
        height: 25px;
        z-index: 1;
        transition: 500ms;
    }
    .heart-filled {
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 30px;
        height: 25px;
        z-index: 2;
        transition: 500ms;
    }
    .not-filled {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 30px;
        height: 25px;
        z-index: 0;
        transition: 500ms;
    }
    .play {
        position: absolute;
        transition: 500ms;
        &:hover {
            filter: brightness(120%);
        }
        cursor: pointer;
        height: 75px;
        bottom: -37px;
        left: calc(50% - 37.5px);
        border-radius: 50%;
        box-shadow: 5px 5px 26px rgba(0, 0, 0, 0.26);
    }
    .content {
        @include flexCenter(column);
        height: 100%;
        background: linear-gradient(rgba(15, 26, 64, 0.5), rgba(15, 26, 64, 0.5));
        border-radius: 32.7px;
        .title {
            font-size: 2.3rem;
            text-align: center;
            line-height: 35px;
            width: 65%;
            margin-bottom: 10px;
            margin-top: 10px;
            font-family: 'Poppins-light';
            color: white;
        }
        .listened {
            margin-top: 30px;
            height: 35px;
        }
        .benefits {
            .ben-1 {
                margin: 27px 0 2px 0;
                font-weight: 400;
            }
            .ben-2 {
                margin: 2px 0;
                font-weight: 400;
            }
            .ben-3 {
                margin: 2px 0;
                font-weight: 400;
            }
            color: white;
            font-size: 0.9rem;
            margin: -10px 0 0 0;
            padding: 0 0 0 20px;
        }
        .listened {
            opacity: 1;
        }
    }
    .length {
        margin: 0;
        color: white;
        font-size: 0.7rem;
    }
    .spacer {
        height: 18.4px;
        width: 33.15px;
    }
    .track-container {
        @media (max-height: 535px) {
            transform: scale(0.8);
        }
        @media (min-height: 700px) {
            height: 430px;
        }
        background-repeat: no-repeat;
        background-size: cover;
        height: 350px;
        width: 260px;
        border-radius: 32.7px;
        box-shadow: 5px 5px 26px rgba(0, 0, 0, 0.26);
    }
}

.locked {
    cursor: pointer;
}