.categories-container {
    cursor: pointer;
    @media (min-width: $desktopBreak) {
        .journey {
            img {
                height: 175px;
                min-width: 174.23px;
            }
            background: #3b3d50;
            border-radius: 43px;
            padding: 20px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            &:hover {
                filter: brightness(120%);
            }
            transition: 500ms;
            @include flexCenter(row);
            width: auto;
            height: 250px;
            margin: 10px;
            .subtitle {
                width: 200px;
            }
        }
    }
}

.coming-soon-outer-container {
    @media (min-width: $desktopBreak) {
        .journey {
            .coming-soon-image {
                height: 160px;
            }
            padding: 20px;
            @include flexCenter(row);
            width: auto;
            height: 250px;
            margin: 10px;
            .subtitle {
                width: 200px;
            }
        }
    }
}

.journey {
    @include flexCenter(row);
    width: 370px;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 50px;
    img {
        height: 125px;
        min-width: 124.45px;
    }
}

.title {
    color: $accent;
    width: 100px;
    font-weight: 350;
    font-size: 1.5rem;
    line-height: 25px;
    font-family: 'Poppins', sans-serif;
}

.coming-soon-title {
    color: grey;
    width: 100px;
    font-weight: 350;
    font-size: 1.5rem;
    line-height: 25px;
}

.subtitle {
    @media (min-width: 800px) {
        width: 300px;
    }
    color: white;
    font-weight: 300;
    font-size: 0.9rem;
    width: 182px;
    line-height: 20px;
}

.coming-soon-image {
    height: 110px;
    margin-right: 8px;
    margin-left: 5px;
}