.subscribe-container {
    @media (min-width: 800px) {
        @include flexCenter(row);
    }
    @media (min-width: $desktopBreak) {
        padding: 0 0 0 200px;
        height: 100vh;
    }
    @media (max-height: 440px) {
        padding-left: 100px;
    }
    @media (max-height: 440px) and (max-width: $desktopBreak) {
        padding-left: 100px;
        padding-bottom: 0;
    }
    padding-bottom: 90px;
    @include flexCenter(column);
    .sub {
        position: relative;
        background: #f2f4f2;
        @include flexCenter(column);
        margin: 10px 20px;
        padding: 30px 20px;
        width: 90%;
        height: 417px;
        max-width: 460px;
        border-radius: 27px;
        span {
            text-align: center;
        }
        h1 {
            color: $accent;
        }
        .sub-title {
            font-size: 1.7rem;
            font-weight: 600;
        }
        .sub-subtitle {
            font-size: 1.2rem;
            margin-bottom: 20px;
        }
        .savings {
            position: absolute;
            top: 140px;
            height: 30px;
            color: white;
            padding: 5px 10px;
            @include flexCenter(row);
            .left {
                width: 0;
                height: 0;
                margin-right: -1px;
                border-top: 26.4px solid $accent;
                border-left: 26.4px solid transparent;
            }
            .middle {
                height: 26.4px;
                background: $accent;
                z-index: 1;
            }
            .right {
                width: 0;
                height: 0;
                border-top: 26.4px solid $accent;
                border-right: 26.4px solid transparent;
            }
            .left2 {
                position: absolute;
                left: 11px;
                width: 0;
                height: 0;
                border-bottom: 26.4px solid $accent;
                border-left: 26.4px solid transparent;
            }
            .right2 {
                position: absolute;
                right: 11px;
                width: 0;
                height: 0;
                border-bottom: 26.4px solid $accent;
                border-right: 26.4px solid transparent;
            }
        }
    }
    .break {
        background: transparent;
    }
    button {
        background: $accent;
        font-size: 1.1rem;
        width: 210px;
        height: 60px;
        cursor: pointer;
        color: white;
        border: none;
    }
}

.month {
    button {
        background: transparent;
        color: black;
        border: 2px solid black;
    }
}