.admin-track-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: whitesmoke;
    border-radius: 27px;
    padding: 50px;
    margin: 30px;
    min-width: 624.48px;
    span {
        font-weight: bold;
    }
    .free-container {
        padding: 20px;
        .free-track-button {
            margin: 5px;
            width: 40px;
        }
        .enabled {
            border: 2px solid $accent;
            background: $accent;
            color: white;
        }
    }
    .description-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 20px 20px 20px;
    }
    .tags-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            font-size: 0.8rem;
            margin: 0 5px;
        }
        .tags-inner {
            display: flex;
            max-width: 40vw;
            flex-wrap: wrap;
            .admin-tag {
                padding: 0 3px;
                border: 2px solid transparent;
                cursor: pointer;
                margin: 2px 1px;
                background: rgba(219, 219, 219, 0.637);
                border-radius: 27px;
                &:hover {
                    background: $accent;
                    border-radius: 27px;
                    transition: 300ms;
                    color: white;
                }
            }
            .selected-tag {
                border-radius: 27px;
                background: $accent;
                padding: 0 3px;
                color: white;
                margin: 2px 1px;
            }
        }
    }
    .benefits-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
        input {
            width: 30vw;
        }
        span {
            margin-top: 10px;
        }
    }
    .categories-container {
        text-align: center;
        margin-top: 20px;
        .category-span {
            font-size: 1.5rem;
            color: $accent;
        }
        .categories-inner {
            display: flex;
            .hide {
                display: none;
            }
            .admin-category {
                margin: 0 5px;
                padding: 0 5px;
                background: rgba(219, 219, 219, 0.637);
                border-radius: 27px;
                &:hover {
                    background: $accent;
                    transition: 300ms;
                    border-radius: 27px;
                    color: white;
                }
            }
        }
    }
    .submit {
        margin-top: 20px;
    }
    .uploads {
        margin-top: 20px;
        text-align: center;
        .s3-upload {
            margin: 10px;
        }
    }
}