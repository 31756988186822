.journeys-container {
    width: 100vw;
    @media (min-width: $desktopBreak) {
        padding-left: 200px;
        padding-bottom: 0;
    }
    @media (max-height: 440px) {
        padding: 10px 0 0 100px;
    }
    @include flexCenter(column);
    background: $bg;
    padding: 30px 20px 80px 20px;
    header {
        @include flexCenter(column);
        text-align: center;
        .title {
            margin-bottom: 10px;
            width: 80vw;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
        }
        .subtitle {
            margin: 0 0 25px 0;
            width: 55vw;
        }
    }
    main {
        @media (min-width: $desktopBreak) {
            display: grid;
            grid-template: 1fr / 1fr;
        }
        @media (min-width: 1156px) {
            display: grid;
            grid-template: 1fr / 1fr 1fr;
        }
        max-width: 1031px;
        @include flexCenter(column);
        align-items: flex-start;
    }
}