@import './variables.scss';
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import './components/Dashboard/dashboard';
@import './components/Explore/explore';
@import './components/Learn/learn';
@import './components/Journeys/journeys';
@import './components/LoggedOutLanding/loggedOutLanding';
@import './components/ForgotPassword/forgotPassword';
@import './components/Nav/nav';
@import './components/Player/player';
@import './components/TracksList/tracksList';
@import './components/NotFound/notFound';
@import './components/Track/track';
@import './components/Categories/categories';
@import './components/BonusTrack/bonusTrack';
@import './components/BlogPost/blogPost';
@import './components/FavoriteTrack/favoriteTrack';
@import './components/SubscribeModal/subscribeModal';
@import './components/Subscribe/subscribe';
@import './components/Payment/payment';
@import './components/SettingsModal/settingsModal';
@import './components/AddToHomescreenModal/addToHomescreenModal';
@import './components/Admin/admin';
@import './components/Admin/adminTrack';
@import './components/Admin/adminTag';
@import './components/Admin/adminBlog';
@import './components/Admin/adminJourney';
@import './components/Admin/adminBonusTrack';
@import './components/Admin/adminPublish';
@import './components/Admin/adminUserList';
@import './components/Admin/adminUserSearch';
@import './components/ResetPassword/resetPassword';
html {
    box-sizing: border-box;
    background: $bg;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.App {
    font-family: 'Poppins-Light';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;
    //css reset
    margin: 0;
    padding: 0;
    //disable text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.loading-results {
    height: 70vh;
    @include flexCenter(column);
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    background: $accent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #9683df;
}