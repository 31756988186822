.reset-container {
    @include flexCenter(column);
    width: 100vw;
    height: 100vh;
    .inputs-container {
        @media (max-width: 500px) {
            width: 100%;
        }
        h3 {
            text-align: center;
            color: #7a70ff;
        }
        width: 70%;
        min-height: 50%;
        max-width: 600px;
        border-radius: 30px;
        border: solid 1px #707070;
        background-color: #ffffff;
        padding: 35px 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .password {
            position: relative;
            transition: 500ms;
            display: flex;
            flex-direction: column;
            text-align: left;
            color: #7a70ff;
            font-size: 0.9rem;
            margin: 10px;
            span {
                margin: 0 0 8px 10px;
            }
            input {
                height: 30px;
                border-radius: 25px;
                border: solid 1px #707070;
                padding: 0 35px 0 15px;
                &:focus {
                    outline: none;
                }
            }
        }
        .pass-invalid {
            text-align: center;
            color: red;
            margin-top: 20px;
        }
    }
    .eyeball {
        position: absolute;
        cursor: pointer;
        right: 18px;
        bottom: 15px;
        height: 20px;
    }
    .reset-button {
        background: #7a70ff;
        color: white;
        border-radius: 25px;
        border: none;
        margin: auto;
        width: 150px;
        padding: 10px;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
}

.spc {
    height: 25px;
}