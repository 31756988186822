@font-face {
    font-family: 'Poppins-Light';
    src: url('./fonts/Poppins-Light/Poppins-Light.ttf.woff') format('woff'), url('./fonts/Poppins-Light/Poppins-Light.ttf.svg') format('svg'), url('./fonts/Poppins-Light/Poppins-Light.ttf.eot');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}