.admin-publish-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: whitesmoke;
    border-radius: 27px;
    padding: 20px;
    min-width: 624.48px;
    margin: 30px;
    span {
        font-weight: bold;
    }
    input {
        margin: 10px;
    }
    .tracks {
        text-align: center;
        .track-to-publish {
            color: $accent;
        }
        .tracks-container {
            display: flex;
            flex-direction: row;
            max-width: 40vw;
            flex-wrap: wrap;
            margin: 20px;
            div {
                font-size: 0.8rem;
                margin: 2px 5px;
                cursor: pointer;
                padding: 0 5px;
                background: rgba(219, 219, 219, 0.637);
                border-radius: 27px;
                &:hover {
                    background: $accent;
                    transition: 300ms;
                    border-radius: 27px;
                    color: white;
                }
            }
            .published {
                background: rgba(4, 170, 4, 0.473);
            }
            .unpublished {
                background: rgba(255, 34, 34, 0.493);
            }
        }
    }
    .parent-track {
        font-size: 1.5rem;
        color: $accent;
    }
    .uploads {
        display: flex;
        flex-direction: column;
        margin: 20px;
        input {
            margin: 20px;
        }
    }
}