$bgLight: #353849;
$bg: #272A3D;
$text:#848484;
$textLight:#e0e0e0;
$accent: #a89cd9;
$desktopBreak: 1000px;
@mixin flexCenter($direction) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

;
@mixin lineClamp($numLines: 2, $lineHeight: 1.412) {
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: block;
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    max-height: $numLines * $lineHeight + unquote('em');
}