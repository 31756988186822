.admin-user-search-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: whitesmoke;
    border-radius: 27px;
    min-width: 624.48px;
    height: 100vh;
    padding: 20px;
    margin: 30px;
    .top {
        display: flex;
        position: sticky;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .bottom {
        width: 100%;
        overflow-x: scroll;
    }
    h3 {
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    span {
        font-weight: bold;
    }
    input {
        margin: auto;
        margin-bottom: 20px;
        width: 100%;
    }
    button {
        margin: 0 3px;
    }
    .user-item {
        margin: 10px 0;
        padding: 5px;
        border-bottom: 1px solid black;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .confirm {
        background: white;
        @include flexCenter(column);
        border-radius: 25px;
        height: 200px;
        width: 300px;
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 150px);
        .buttons {
            @include flexCenter(row);
        }
    }
}