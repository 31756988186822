.dark-bg {
    position: absolute;
    @include flexCenter(column);
    height: 100%;
    width: 100%;
    z-index: 3;
    position: fixed;
    backdrop-filter: blur(2px);
    transition: 300ms;
    background: rgba(0, 0, 0, 0.24);
    .add-to-homescreen-modal {
        color: $accent;
        position: relative;
        @include flexCenter(column);
        text-align: center;
        justify-content: flex-start;
        padding: 50px 10px 30px 10px;
        overflow-y: scroll;
        height: 70%;
        width: 90%;
        max-width: 600px;
        z-index: 3;
        background: white;
        border-radius: 27px;
        div {
            margin-bottom: 5px;
        }
        img {
            height: 300px;
        }
        .dismiss {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
            height: 25px;
        }
    }
    .add-to-homescreen-settings {
        @media (min-width: $desktopBreak) {
            margin-left: 200px;
        }
    }
}