.forgot {
    color: #7a70ff;
    cursor: pointer;
    margin-top: 10px;
}

.forgot-pass {
    @include flexCenter(column);
    text-align: center;
    position: relative;
    color: #7a70ff;
    font-size: 1.1rem;
    height: 100%;
    width: 100%;
    margin-bottom: -20px;
    .back {
        height: 25px;
        position: absolute;
        left: 0;
        top: -20px;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
    .inputs {
        @include flexCenter(column);
        input {
            &:focus {
                outline: none;
            }
            padding: 0 20px;
            text-align: center;
            border: 1px solid #707070;
            border-radius: 27px;
            width: 240px;
            height: 45px;
            margin: 15px 0 30px 0;
            font-size: 0.9rem;
            @include flexCenter(column);
        }
        button {
            text-decoration: none;
            border: none;
            color: white;
            border-radius: 27px;
            background: #7a70ff;
            padding: 10px 15px;
            cursor: pointer;
        }
    }
    .msg {
        height: 30px;
    }
}