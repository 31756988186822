.sign-up-modal-settings {
    @media (min-width: $desktopBreak) {
        margin: 0 0 0 200px;
        width: 45%;
        max-width: 600px;
    }
    hr {
        margin-left: -20px;
        width: calc(100% + 40px);
        opacity: 0.4;
    }
    h4 {
        margin: 0;
    }
    h5 {
        color: $accent;
        margin: 0;
    }
    span {
        font-size: 0.85rem;
        color: #707070;
    }
    #break {
        height: 10px;
    }
    width: 90%;
    max-width: 550px;
    margin-bottom: 90px;
    border-radius: 30px;
    border: solid 1px #707070;
    background-color: #ffffff;
    padding: 30px 20px;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    overflow-x: scroll;
    .back {
        height: 25px;
        position: absolute;
        left: -3px;
        top: -12px;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
    .settings-nav {
        text-align: center;
        position: relative;
        color: $accent;
        font-size: 1.1rem;
        font-weight: 600;
        .logout-button {
            position: absolute;
            left: -5px;
            top: -15px;
            color: $accent;
            border: 1px solid #707070;
            border-radius: 27px;
            background: white;
            padding: 8px 10px;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
        .dismiss-settings {
            position: absolute;
            height: 25px;
            right: -5px;
            top: -15px;
            cursor: pointer;
        }
    }
    .settings-name {
        @include flexCenter(row);
        justify-content: space-between;
        .name-left {
            @include flexCenter(column);
            align-items: flex-start;
        }
        .name-right {
            color: $accent;
            cursor: pointer;
        }
    }
    .settings-email {
        @include flexCenter(row);
        justify-content: space-between;
        .email-left {
            @include flexCenter(column);
            align-items: flex-start;
            span {
                @media (max-width: 400px) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 80%;
                }
            }
        }
        .email-right {
            color: $accent;
            cursor: pointer;
        }
    }
    .settings-subscription {
        @include flexCenter(row);
        justify-content: space-between;
        .subscription-left {
            @include flexCenter(column);
            align-items: flex-start;
            span {
                @media (max-width: 400px) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        .subscription-right {
            @include flexCenter(column);
            align-items: flex-end;
            .cancel-sub {
                color: $accent;
                cursor: pointer;
            }
        }
    }
    .settings-billing {
        @include flexCenter(row);
        justify-content: space-between;
        .billing-left {
            @include flexCenter(column);
            align-items: flex-start;
        }
        .billing-right {
            color: $accent;
            cursor: pointer;
        }
    }
    .settings-contact-home-icon {
        @include flexCenter(row);
        justify-content: space-around;
        span {
            @media (min-width: 800px) {
                font-size: 1rem;
            }
            @media (min-width: $desktopBreak) {
                font-size: 0.9rem;
            }
            @media (min-width: 1200px) {
                font-size: 1rem;
            }
            font-size: 0.9rem;
            text-align: center;
            color: $accent;
            margin-top: 10px;
            cursor: pointer;
        }
        a {
            @media (min-width: 800px) {
                font-size: 1rem;
            }
            @media (min-width: $desktopBreak) {
                font-size: 0.9rem;
            }
            @media (min-width: 1200px) {
                font-size: 1rem;
            }
            text-decoration: none;
            text-align: center;
            font-size: 0.9rem;
            margin-top: 10px;
            color: $accent;
        }
    }
}

.sign-up-modal-settings::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.save {
    border: none;
    background: $accent;
    border-radius: 27px;
    padding: 12px 20px;
    color: white;
    margin-top: 20px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.edit {
    position: relative;
    @include flexCenter(column);
    a {
        color: $accent;
        text-decoration: none;
        display: inline;
        cursor: pointer;
        font-size: 0.9rem;
    }
    .title-edit-settings {
        @media (min-width: $desktopBreak) {
            margin: 0 0 20px 0;
        }
        color: $accent;
        margin: 20px 0;
        font-size: 1.1rem;
        font-weight: 600;
    }
    .inputs {
        @media (min-width: 600px) {
            width: 75%;
        }
        @include flexCenter(column);
        width: 90%;
        align-items: flex-start;
        position: relative;
        span {
            color: $accent;
            font-size: 0.9rem;
        }
        .pass2-outer {
            position: relative;
            width: 100%;
            .eyeball {
                position: absolute;
                // right: 10px;
                top: 11px;
            }
        }
        input {
            height: 30px;
            color: $accent;
            width: 100%;
            border-radius: 25px;
            border: solid 1px #707070;
            padding: 0 30px 0 15px;
            margin: 5px 0;
            &:focus {
                outline: none;
            }
        }
        .pass-invalid {
            font-size: 0.7rem;
        }
    }
}

.cancel-sub-container {
    p {
        color: #707070;
        margin: 0;
        font-size: 0.9rem;
    }
    .title-edit-settings {
        color: $accent;
    }
    .cancel {
        color: $accent;
        border: 1px solid #707070;
        border-radius: 27px;
        background: white;
        padding: 12px 8px;
        cursor: pointer;
        margin-left: 5px;
        &:focus {
            outline: none;
        }
    }
    .do-not-cancel {
        margin-right: 5px;
        padding: 12px 10px;
    }
}

.empty-input {
    color: red !important;
}

.settings-sub {
    cursor: pointer;
    color: $accent !important;
}