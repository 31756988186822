.blog-post-container {
    @media (min-width: 760px) {
        margin: 30px 30px;
    }
    @media (min-width: $desktopBreak) {
        @include flexCenter(row);
        width: 600px;
        margin: 15px 0;
    }
    &:hover {
        filter: brightness(120%);
    }
    transition: 500ms;
    @include flexCenter(column);
    cursor: pointer;
    width: 280px;
    margin-bottom: 30px;
    img {
        @media (min-width: $desktopBreak) {
            height: 172.45px;
            min-width: 210px;
        }
        object-fit: cover;
        height: 172.45px;
        width: 280px;
        z-index: 1;
    }
    .blog-text {
        @media (min-width: $desktopBreak) {
            min-width: 90%;
            margin: 0 0 0 -30px;
            height: 172.45px;
            padding: 25px 20px 20px 60px;
        }
        margin-top: -30px;
        padding: 30px 15px 20px 15px;
        background: $bgLight;
        border-radius: 27px;
        max-height: 180px;
        max-width: 280px;
        h3 {
            @media (min-width: $desktopBreak) {
                @include lineClamp();
                font-size: 1.3rem;
                line-height: 30px;
                margin: 0;
            }
            color: $accent;
            line-height: 20px;
            font-size: 1.1rem;
            margin-bottom: 0;
            max-height: 60px;
            @include lineClamp();
        }
        p {
            @media (min-width: $desktopBreak) {
                @include lineClamp(3, 1.412);
                max-width: 600px;
                margin-top: 10px;
                max-height: 80px;
            }
            color: white;
            font-size: 0.85rem;
            max-height: 42px;
            max-width: 260px;
            @include lineClamp();
        }
    }
}