.explore-container {
    @media (min-width: $desktopBreak) {
        padding: 50px 0 0 200px;
    }
    @media (max-height: 440px) {
        padding-left: 100px;
    }
    @media (max-height: 440px) and (max-width: $desktopBreak) {
        padding-left: 100px;
        padding-bottom: 0;
    }
    @include flexCenter(column);
    padding: 40px 10px 90px 10px;
    .top {
        @media (min-width: $desktopBreak) {
            width: 90%;
            text-align: left;
        }
        text-align: center;
        color: $accent;
        .filter-title {
            @media (min-width: $desktopBreak) {
                font-size: 1.5rem;
            }
            font-size: 1.1rem;
            font-weight: 700;
            letter-spacing: 1.32px;
            margin-bottom: 8px;
        }
        .filter-subtitle {
            @media (min-width: $desktopBreak) {
                font-size: 1.1rem;
            }
            font-size: 0.9rem;
            margin-bottom: 15px;
        }
    }
    .filter {
        @media (min-width: 600px) {
            width: 80%;
        }
        @media (min-width: $desktopBreak) {
            width: 90%;
            padding: 25px 25px;
        }
        @media (max-height: 440px) and (max-width: $desktopBreak) {
            width: 80%;
        }
        background: $bgLight;
        border-radius: 20px;
        height: auto;
        min-height: 150px;
        width: 100%;
        margin: 10px 0;
        padding: 10px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
    }
    .results {
        @media (min-width: 600px) {
            width: 80%;
        }
        @media (min-width: 700px) {
            display: grid;
            grid-template: 275px / 275px 275px;
            justify-items: center;
        }
        @media (min-width: $desktopBreak) {
            width: 90%;
            @include flexCenter(row);
            justify-content: flex-start;
            padding-left: 20px;
            padding-top: 30px;
            min-height: 316.4px;
            -ms-overflow-style: none;
            /* Internet Explorer 10+ */
            scrollbar-width: none;
            /* Firefox */
            overflow-x: scroll;
            overflow-y: hidden;
        }
        @media (max-height: 440px) and (max-width: $desktopBreak) {
            width: 80%;
        }
        @include flexCenter(column);
        position: relative;
        background: $bgLight;
        border-radius: 20px;
        height: auto;
        min-height: 311px;
        width: 100%;
        margin: 10px 0;
        padding: 20px 10px 10px 10px;
    }
    .tag {
        @media (min-width: 600px) {
            max-height: 40.33px;
            padding: 5px 20px;
            margin: 5px 5px;
        }
        cursor: pointer;
        background: $bg;
        max-height: 33.33px;
        color: $accent;
        border-radius: 25.9px;
        padding: 5px 15px;
        margin: 5px 3px;
        line-height: 1.3;
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 1.21px;
        @include flexCenter(column);
    }
    .selected-tag {
        background: white;
        color: #5f51b1;
        transition: 500ms;
    }
    .results-title {
        @media (min-width: 434px) {
            top: 190px;
        }
        @media (min-width: $desktopBreak) {
            display: unset;
            font-size: 1.3rem;
            width: 400px;
            left: 10px;
        }
        color: $accent;
        top: 235px;
        left: calc(50% - 140px);
        text-align: center;
        position: absolute;
        width: 280px;
        z-index: 1;
        display: none;
    }
    .results-title-mobile {
        @media (min-width: $desktopBreak) {
            display: none;
        }
        top: 15px;
        left: calc(50% - 140px);
        text-align: center;
        position: absolute;
        color: $accent;
    }
}

.buffer {
    min-width: 5px;
    width: 25px;
    height: 1px;
    margin: 40px 10px 0 10px;
}