.payment-container {
    @media (min-width: $desktopBreak) {
        padding: 0 0 0 200px;
    }
    @media (max-height: 440px) {
        padding-left: 100px;
    }
    @media (max-height: 440px) and (max-width: $desktopBreak) {
        padding-left: 100px;
        padding-bottom: 0;
    }
    height: 100vh;
    padding-bottom: 90px;
    @include flexCenter(column);
    .payment-inner {
        @media (min-width: 760px) {
            width: 55%;
        }
        .loading-results-payment {
            position: absolute;
            top: calc(50% - 37.5px);
            right: calc(50% - 37.5px)
        }
        position: relative;
        width: 90%;
        max-width: 500px;
        background: white;
        border-radius: 27px;
        padding: 30px;
        color: $accent;
        font-size: 1.2rem;
        font-weight: 600;
        .StripeElement {
            margin-top: 50px;
        }
        label {
            font-size: 1rem;
        }
        .coupon-container {
            margin-bottom: -35px;
            .coupon-input {
                margin: 0 25px 0 0;
                border: 0px solid;
                height: 20px;
                width: 160px;
                color: rgba(0, 0, 0, 0.473);
                &:focus {
                    outline: none;
                }
            }
            .redeem {
                padding: 6px 10px;
            }
        }
        .confirm-sub {
            background: $accent;
            color: white;
            border-radius: 27px;
            border: none;
            cursor: pointer;
            padding: 10px 12px;
            margin-right: 15px;
            &:focus {
                outline: none;
            }
        }
        .sub-buttons {
            margin-top: 50px;
            @include flexCenter(row);
            .cancel-sub {
                background: transparent;
                color: $accent;
                border-radius: 27px;
                border: 1px solid #707070;
                cursor: pointer;
                padding: 10px 18px;
                margin-left: 15px;
                &:focus {
                    outline: none;
                }
            }
        }
    }
    #card-errors {
        color: red;
        font-size: 0.8rem;
        position: absolute;
    }
}