.admin-tag-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: whitesmoke;
    border-radius: 27px;
    min-width: 624.48px;
    padding: 20px;
    margin: 30px;
    span {
        font-weight: bold;
    }
    input {
        margin: 10px;
    }
}