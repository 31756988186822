.admin-journey-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: whitesmoke;
    border-radius: 27px;
    padding: 20px;
    min-width: 624.48px;
    margin: 30px;
    span {
        font-weight: bold;
    }
    input {
        margin: 10px;
        min-width: 350px;
    }
    .coming-soon-container {
        padding: 20px;
        .coming-soon-button {
            margin: 5px;
            width: 40px;
        }
        .enabled {
            border: 2px solid $accent;
            background: $accent;
            color: white;
        }
    }
    .upload {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}