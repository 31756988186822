.nav-container {
    @media (min-width: $desktopBreak) {
        @include flexCenter(column);
        left: 0;
        height: 100%;
        width: 200px;
        padding-top: 35px;
        box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.212);
        div {
            margin: 20px 0;
        }
    }
    @media (max-height: 440px) {
        @include flexCenter(column);
        padding-top: 0;
        height: 100%;
        width: 100px;
        z-index: 2;
        div {
            margin: 30px 0;
        }
        img {
            display: none;
        }
    }
    @media (max-height: 440px) and (min-width: $desktopBreak) {
        img {
            display: unset;
        }
        span {
            display: none;
        }
    }
    @include flexCenter(row);
    .logo {
        @media (min-width: $desktopBreak) {
            position: absolute;
            top: 20px;
            left: 45px;
            display: unset !important;
            .logo-image {
                height: 75px;
                margin-bottom: -10px;
            }
            .logo-text {
                filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(5892%) hue-rotate(196deg) brightness(92%) contrast(83%);
                top: 75px;
                left: 10px;
                height: 20px;
                position: absolute;
            }
        }
        @media (max-height: 645px) {
            display: none !important;
        }
        display: none;
    }
    z-index: 5;
    padding: 0 10px;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    background: $bg;
    height: 90px;
    width: 100%;
    box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.274);
    div {
        @include flexCenter(column);
        color: $text;
        font-weight: 400;
        font-size: 0.8rem;
        width: 0;
        padding: 0;
        cursor: pointer;
    }
    .container-clicked {
        color: $accent;
        font-weight: 700;
    }
    .journey-container {
        position: relative;
    }
    img {
        @media (min-width: $desktopBreak) {
            height: 45px;
            top: unset;
        }
        @media (max-height: 440px) {
            height: 35px;
        }
        position: absolute;
        top: 25px;
        transition: 500ms;
        height: 30px;
        margin-bottom: 5px;
        z-index: 2;
    }
    span {
        transition: 250ms;
        margin-bottom: -40px;
        font-family: 'Poppins', sans-serif;
    }
    background: $bgLight;
}

.selected {
    position: absolute;
    cursor: pointer;
    z-index: 3;
    transition: 500ms;
}

.not-selected {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    transition: 500ms;
}

.you-container-i {
    @media (min-width: $desktopBreak) {
        position: absolute;
        top: 26%;
    }
    @media (max-height: 645px) {
        top: 21%;
    }
    @media (max-height: 440px) {
        top: 20%;
    }
}

.y {
    @media (min-width: $desktopBreak) {
        position: absolute;
        top: 26%;
    }
    @media (max-height: 645px) {
        top: 21%;
    }
    @media (max-height: 440px) {
        top: 20%;
    }
}

.journeys-container-i {
    @media (min-width: $desktopBreak) {
        position: absolute;
        top: 42.5%;
    }
    @media (max-height: 645px) {
        top: 37.5%;
    }
    @media (max-height: 440px) {
        top: 36.5%;
    }
}

.j {
    @media (min-width: $desktopBreak) {
        position: absolute;
        top: 42.5%;
    }
    @media (max-height: 645px) {
        top: 37.5%;
    }
    @media (max-height: 440px) {
        top: 36.5%;
    }
}

.explore-container-i {
    @media (min-width: $desktopBreak) {
        position: absolute;
        top: 59.5%;
    }
    @media (max-height: 645px) {
        top: 54.5%;
    }
    @media (max-height: 440px) {
        top: 52%;
    }
}

.e {
    @media (min-width: $desktopBreak) {
        position: absolute;
        top: 59.5%;
    }
    @media (max-height: 645px) {
        top: 54.5%;
    }
    @media (max-height: 440px) {
        top: 52%;
    }
}

.learn-container-i {
    @media (min-width: $desktopBreak) {
        position: absolute;
        top: 76%;
    }
    @media (max-height: 645px) {
        top: 71%;
    }
    @media (max-height: 440px) {
        top: 68.5%;
    }
}

.l {
    @media (min-width: $desktopBreak) {
        position: absolute;
        top: 76%;
    }
    @media (max-height: 645px) {
        top: 71%;
    }
    @media (max-height: 440px) {
        top: 68.5%;
    }
}

.journeys-title {
    @media (min-width: $desktopBreak) {
        margin-top: 30px;
    }
}

.explore-title {
    @media (min-width: $desktopBreak) {
        margin-top: 30px;
    }
}

.learn-title {
    @media (min-width: $desktopBreak) {
        margin-top: 30px;
    }
}

.you-title {
    @media (min-width: $desktopBreak) {
        margin-top: 30px;
    }
}