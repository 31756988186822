.admin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding: 50px;
    h1 {
        color: white;
        margin-top: 0;
    }
    h3 {
        margin-top: 0;
        color: $accent;
    }
    .go-back {
        position: absolute;
        left: 10%;
    }
    .admin-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        button {
            margin: 10px;
        }
    }
    .add-track {
        width: 150px;
        height: 50px;
        cursor: pointer;
        font-weight: bold;
    }
    .on {
        background: $accent;
    }
    button {
        cursor: pointer;
    }
}