.admin-user-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: whitesmoke;
    border-radius: 27px;
    padding: 50px;
    margin: 30px;
    min-width: 624.48px;
    min-height: 336.6px;
    .choice-container {
        margin-bottom: 20px;
    }
    .choice {
        padding: 0 10px;
        border: 2px solid transparent;
        cursor: pointer;
        margin: 2px 3px;
        background: rgba(219, 219, 219, 0.637);
        border-radius: 27px;
        &:hover {
            background: $accent;
            border-radius: 27px;
            transition: 300ms;
            color: white;
        }
    }
    .selected-user-type {
        border-radius: 27px;
        background: $accent;
        color: white;
        margin: 2px 3px;
    }
    .submit {
        margin-bottom: 20px;
    }
    .csv {
        font-size: large;
    }
    .download-user {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}